import { Switch, SwitchLabel, Grid, IconButton, CircularProgress } from "@cuda-networks/bds-core";
import { Delete, PlayArrow, StopSharp } from "@material-ui/icons";
import React from "react";
import { ReportScheduleStatus } from "../../../models/ReportSchedule/ReportScheduleStatus";
import { Tooltip } from "@material-ui/core";

export interface IReportScheduleActionsProps {
  onRunReport: (id: number) => void;
  onToggleReportSchedule: (id: number) => void;
  onDeleteSchedule: (id: number) => void;
  onCancelReport: (id: number) => void;
  reportingEnabled: boolean;
  status: ReportScheduleStatus;
}

const ReportScheduleActions: React.FC<IReportScheduleActionsProps> = ({ reportingEnabled, status, onRunReport, onToggleReportSchedule, onDeleteSchedule, onCancelReport }) => {
  const cellStyle: React.CSSProperties = {
    color: reportingEnabled ? "#0088CE" : "#a0a0a0",
    backgroundColor: reportingEnabled ? "inherit" : "#f0f0f0",
    fontSize: "inherit",
    fontStyle: "inherit",
    fontWeight: "inherit",
    alignItems: "center",
  };

  const getRunReportStatusIcon = (status: ReportScheduleStatus) => {
    switch (status) {
      case ReportScheduleStatus.Running:
        return <StopSharp data-testid="stopSharpTestId" />;
      case ReportScheduleStatus.Pending:
        return <CircularProgress size={25} data-testid="circularProgressTestId" />;
      case ReportScheduleStatus.Succeeded:
      case ReportScheduleStatus.Cancelled:
      case ReportScheduleStatus.Failed:
        return <PlayArrow data-testid="playArrowTestId" />;
      default:
        return <PlayArrow />;
    }
  };

  const canCancelReport = (status: ReportScheduleStatus): boolean => {
    return status === ReportScheduleStatus.Pending || status === ReportScheduleStatus.Running;
  };

  return (
    <td style={cellStyle}>
      <Grid container item>
        <Tooltip data-testid="scheduleToolTipTestId" title={reportingEnabled ? "Disable" : "Enable"}>
          <Grid item>
            <SwitchLabel label={""} control={<Switch data-testid="toggleReportSchedule" checked={reportingEnabled} onChange={onToggleReportSchedule} />} style={{ marginTop: "5px" }} />
          </Grid>
        </Tooltip>
        <Grid item style={{ display: "flex", alignItems: "center", position: "relative", marginRight: 10 }}>
          <Tooltip title={canCancelReport(status) ? "Cancel" : "Run"}>
            <IconButton style={{ color: "#0088CE" }} onClick={canCancelReport(status) ? onCancelReport : onRunReport}>
              {getRunReportStatusIcon(status)}
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Delete">
            <IconButton onClick={onDeleteSchedule}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </td>
  );
};

export default ReportScheduleActions;
