import React, { useState, useEffect } from "react";
import { DataTable, Pager, DataTableColumn as Column } from "@cuda-networks/bds-core";
import { process } from "@progress/kendo-data-query";
import ReportScheduleActions, { IReportScheduleActionsProps } from "./ReportScheduleActions";
import { Tooltip, Typography, useMediaQuery } from "@material-ui/core";
import { PAGE_SIZES } from "../../../models/TableConfig";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/store";
import { getButtonCount } from "../../../utility";
import IReportScheduleForDisplay from "../../../models/ReportSchedule/IReportScheduleForDisplay";
import { CheckCircle, Error, HourglassEmpty } from "@material-ui/icons";
import { ReportScheduleStatus } from "../../../models/ReportSchedule/ReportScheduleStatus";

interface IReportSchedulesTableProps {
  reportSchedules: IReportScheduleForDisplay[];
  onEdit: (id: number) => void;
  actions: IReportScheduleActionsProps;
}

const ReportSchedulesTable: React.FC<IReportSchedulesTableProps> = ({ reportSchedules, onEdit, actions }) => {
  const tableState = useSelector((state: IAppState) => state.accountState.reportSchedulesTableState);
  const [buttonCount, setButtonCount] = useState(10);
  const responsiveViewPortTriggerMin = useMediaQuery("(min-width: 1600px)");
  const dataState = {
    skip: tableState.skip,
    take: tableState.take,
    sort: tableState.sort,
    group: tableState.group,
    filter: tableState.filter,
    selectedItem: "any",
    lastSelectedIndex: 0,
    columns: [
      {
        title: "Schedule Name",
        field: "name",
        show: true,
        filter: "text",
        filtrable: false,
        sortable: true,
      },
      {
        title: "Type",
        field: "type",
        show: true,
        filter: "text",
        filtrable: false,
        sortable: true,
      },
      {
        title: "Frequency",
        field: "frequency",
        show: true,
        filter: "text",
        filtrable: false,
        sortable: false,
      },
      {
        title: "Accounts",
        field: "numberOfAccounts",
        show: true,
        filter: "numeric",
        filtrable: false,
        sortable: true,
      },
      {
        title: "Last Export",
        field: "lastExport",
        show: true,
        filter: "text",
        filtrable: false,
        sortable: true,
      },
      {
        title: "Last Status",
        field: "status",
        show: true,
        filter: "numeric",
        filtrable: false,
        sortable: true,
      },
    ],
  };

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: process(reportSchedules, dataState as any),
  });

  useEffect(() => {
    if (reportSchedules) {
      const newState = { ...dataState, ...gridState.dataState };
      setGridState({
        dataState: newState,
        dataResult: process(reportSchedules, newState as any),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportSchedules]);

  const dataStateChange = (e: any): void => {
    setGridState({
      dataState: { ...dataState, ...e.dataState },
      dataResult: process(reportSchedules, e.dataState),
    });
  };

  useEffect(() => {
    setButtonCount(getButtonCount(gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin));
  }, [gridState.dataResult.total, gridState.dataState.take, responsiveViewPortTriggerMin]);

  const ReportScheduleActionsCell = (props: any) => <ReportScheduleActions status={props.dataItem.status} reportingEnabled={props.dataItem.reportingEnabled} onRunReport={() => actions.onRunReport(props.dataItem.id)} onToggleReportSchedule={() => actions.onToggleReportSchedule(props.dataItem.id)} onCancelReport={() => actions.onCancelReport(props.dataItem.id)} onDeleteSchedule={() => actions.onDeleteSchedule(props.dataItem.id)} />;

  interface ReportScheduleCellProps {
    value: string | number;
    reportingEnabled: boolean;
    onEdit?: () => void;
  }
  const getCellStyle = (reportingEnabled: boolean) => ({
    color: reportingEnabled ? "inherit" : "#a0a0a0",
    backgroundColor: reportingEnabled ? "inherit" : "#f0f0f0",
    fontSize: "inherit",
    fontStyle: "inherit",
    fontWeight: "inherit",
  });
  const ReportScheduleNameCell: React.FC<ReportScheduleCellProps> = ({ value, onEdit, reportingEnabled }) => (
    <td style={getCellStyle(reportingEnabled)}>
      <button data-testid="addEditScheduleReportName" onClick={onEdit} style={{ cursor: "pointer", background: "none", border: "none", padding: 0, textAlign: "left" }}>
        <Typography style={{ color: reportingEnabled ? "#0088CE" : "#8EC9D9" }}>{value}</Typography>
      </button>
    </td>
  );

  const ReportScheduleCell: React.FC<ReportScheduleCellProps> = ({ value, reportingEnabled }) => {
    return (
      <td style={getCellStyle(reportingEnabled)}>
        <Typography>{value}</Typography>
      </td>
    );
  };

  const getStatusIcon = (status: ReportScheduleStatus, isDisabled: boolean) => {
    const renderStatusIndicator = (tooltip: string, IconComponent: React.ElementType | null, status: string, color: string, isDisabled: boolean) => (
      <td
        style={{
          color: isDisabled ? "#0088CE" : "#a0a0a0",
          backgroundColor: isDisabled ? "inherit" : "#f0f0f0",
          alignItems: "left",
          justifyContent: "left",
        }}
      >
        <Tooltip title={tooltip}>
          {IconComponent ? (
            <IconComponent
              data-testid="statusIconTestId"
              style={{
                fontSize: 16,
                color: isDisabled ? color : "inherit",
                verticalAlign: "middle",
              }}
            />
          ) : (
            <span style={{ display: "inline-block", width: 16 }} /> // Placeholder for alignment
          )}
        </Tooltip>
        <span data-testid="statusTextTestId" style={{ marginLeft: 5, color: isDisabled ? color : "inherit" }}>
          {status}
        </span>{" "}
        {/* Add some space between icon and text */}
      </td>
    );

    switch (status) {
      case ReportScheduleStatus.None:
        return renderStatusIndicator("No Exports", null, "No Exports", "#a0a0a0", isDisabled);
      case ReportScheduleStatus.Pending:
        return renderStatusIndicator(ReportScheduleStatus[ReportScheduleStatus.Pending], HourglassEmpty, ReportScheduleStatus[ReportScheduleStatus.Pending], "#a0a0a0", isDisabled);
      case ReportScheduleStatus.Running:
        return renderStatusIndicator(ReportScheduleStatus[ReportScheduleStatus.Running], HourglassEmpty, ReportScheduleStatus[ReportScheduleStatus.Running], "#a0a0a0", isDisabled);
      case ReportScheduleStatus.Succeeded:
        return renderStatusIndicator("Last run succeeded", CheckCircle, ReportScheduleStatus[ReportScheduleStatus.Succeeded], "#4caf50", isDisabled);
      case ReportScheduleStatus.Cancelled:
        return renderStatusIndicator("Last run was cancelled", null, ReportScheduleStatus[ReportScheduleStatus.Cancelled], "#000000", isDisabled);
      case ReportScheduleStatus.Failed:
        return renderStatusIndicator("Last run has failed", Error, ReportScheduleStatus[ReportScheduleStatus.Failed], "#f44336", isDisabled);
      default:
        return null;
    }
  };

  return (
    <DataTable
      className={"noScrollbar noBorders ReportSchedulesTable"}
      pageConfig={{
        pageable: {
          pageSizes: PAGE_SIZES,
          buttonCount: buttonCount,
        },
        skip: tableState.skip,
        take: tableState.take,
        total: gridState.dataResult.total,
      }}
      sortConfig={{
        sortable: true,
        sort: tableState.sort,
      }}
      data={gridState.dataResult}
      filter={gridState.dataState.filter}
      pager={gridState.dataResult.data.length > 0 && Pager}
      onDataStateChange={dataStateChange}
      selectedField="selected"
      {...(gridState.dataState as any)}
    >
      <Column key={"reportScheduleName"} title={"Schedule Name"} field={"name"} cell={(props: any) => <ReportScheduleNameCell onEdit={() => onEdit(props.dataItem.id)} value={props.dataItem.name} reportingEnabled={props.dataItem.reportingEnabled} />} />
      <Column key={"reportScheduleType"} title={"Type"} field={"type"} cell={(props: any) => <ReportScheduleCell value={props.dataItem.type} reportingEnabled={props.dataItem.reportingEnabled} />} />
      <Column key={"reportScheduleFrequency"} title={"Frequency"} field={"frequencyType"} cell={(props: any) => <ReportScheduleCell value={props.dataItem.frequencyType} reportingEnabled={props.dataItem.reportingEnabled} />} />
      <Column key={"reportScheduleNumberOfAccounts"} title={"Accounts"} field={"numberOfAccounts"} cell={(props: any) => <ReportScheduleCell value={props.dataItem.numberOfAccounts} reportingEnabled={props.dataItem.reportingEnabled} />} />
      <Column key={"reportScheduleLastExport"} title={"Last Export"} field={"lastExport"} cell={(props: any) => <ReportScheduleCell value={props.dataItem.lastExport} reportingEnabled={props.dataItem.reportingEnabled} />} />
      <Column key={"reportScheduleLastStatus"} title={"Last Status"} field={"status"} cell={(props: any) => getStatusIcon(props.dataItem.status, props.dataItem.reportingEnabled)} />
      <Column key={"reportScheduleActions"} title={"Actions"} cell={ReportScheduleActionsCell} width={250} />
    </DataTable>
  );
};

export default ReportSchedulesTable;
