import { Dialog, DialogActions, DialogContent, Button, Grid, DialogTitle, TextField } from "@cuda-networks/bds-core";
import React, { ChangeEvent, useEffect, useState } from "react";
import { handleBackdropClick } from "../../../utility";
import DialogStepper from "../../DialogStepper";
import TypeAndFrequencyStep from "./DialogSteps/TypeAndFrequencyStep";
import { ReportScheduleType } from "../../../models/ReportSchedule/ReportScheduleType";
import { ReportScheduleBase } from "../../../models/ReportSchedule/Types/ReportScheduleBase";
import DailyReportSchedule from "../../../models/ReportSchedule/Types/DailyReportSchedule";
import { ReportScheduleFrequencyType } from "../../../models/ReportSchedule/ReportScheduleFrequencyType";
import WeeklyReportSchedule from "../../../models/ReportSchedule/Types/WeeklyReportSchedule";
import IAccount from "../../../models/IAccount";
import useAccountsSelection from "../useAccountsSelection";
import MonthlyReportSchedule from "../../../models/ReportSchedule/Types/MonthlyReportSchedule";
import { ReportScheduleDaysOfMonthBitmask } from "../../../models/ReportSchedule/ReportScheduleDaysOfMonthBitmask";
import { ReportScheduleMonthBitmask } from "../../../models/ReportSchedule/ReportScheduleMonthBitmask";
import AnnualReportSchedule from "../../../models/ReportSchedule/Types/AnnualReportSchedule";
import { ReportScheduleDaysOfWeekBitmask } from "../../../models/ReportSchedule/ReportScheduleDaysOfWeekBitmask";
import AccountsStep from "./DialogSteps/AccountsStep";
import EmailRecipientStep from "./DialogSteps/EmailRecipientStep";

export const TypeAndFrequencyStepLabel = "Type & Frequency";
export const AccountsStepLabel = "Accounts";
export const EmailRecipientStepLabel = "Email Recipient";

export const ReportTypes = [
  { id: ReportScheduleType.UsageData, value: "Usage Data" },
  { id: ReportScheduleType.UserBillingList, value: "User Billing List" },
];

export const Frequencies = [
  { id: ReportScheduleFrequencyType.Daily, value: ReportScheduleFrequencyType[ReportScheduleFrequencyType.Daily] },
  { id: ReportScheduleFrequencyType.Weekly, value: ReportScheduleFrequencyType[ReportScheduleFrequencyType.Weekly] },
  { id: ReportScheduleFrequencyType.Monthly, value: ReportScheduleFrequencyType[ReportScheduleFrequencyType.Monthly] },
  { id: ReportScheduleFrequencyType.Annually, value: ReportScheduleFrequencyType[ReportScheduleFrequencyType.Annually] },
];

export const DaysOfTheMonth = [
  { id: ReportScheduleDaysOfMonthBitmask.First, value: "1st" },
  { id: ReportScheduleDaysOfMonthBitmask.Second, value: "2nd" },
  { id: ReportScheduleDaysOfMonthBitmask.Third, value: "3rd" },
  { id: ReportScheduleDaysOfMonthBitmask.Fourth, value: "4th" },
  { id: ReportScheduleDaysOfMonthBitmask.Fifth, value: "5th" },
  { id: ReportScheduleDaysOfMonthBitmask.Sixth, value: "6th" },
  { id: ReportScheduleDaysOfMonthBitmask.Seventh, value: "7th" },
  { id: ReportScheduleDaysOfMonthBitmask.Fourteenth, value: "14th" },
  { id: ReportScheduleDaysOfMonthBitmask.LastDayOfMonth, value: "Last" },
];

export const DisplayedMonths = Object.keys(ReportScheduleMonthBitmask)
  .filter(key => isNaN(Number(key)))
  .map(key => ({ id: ReportScheduleMonthBitmask[key as keyof typeof ReportScheduleMonthBitmask], value: key }))
  .filter(entry => entry.id !== ReportScheduleMonthBitmask.None);

interface IAddEditScheduleDialogProps {
  reportSchedule: ReportScheduleBase | undefined;
  onSubmit: (reportSchedule: ReportScheduleBase) => void;
  onCancel: () => void;
  showDialog: boolean;
  isActionInProgress: boolean;
  dialogStep: number;
  title: string;
  selectedAccount: IAccount | undefined;
}

export interface IDayOfTheWeek {
  id: number;
  value: string;
  isSelected: boolean;
}

const AddEditScheduleDialog: React.FC<IAddEditScheduleDialogProps> = ({ reportSchedule, showDialog, dialogStep, onCancel, onSubmit, isActionInProgress, title, selectedAccount }) => {
  const [nextButtonLabel, setNextButtonLabel] = useState("NEXT");
  const [dialogStepsNames, setDialogStepsNames] = useState(reportSchedule ? getAddScheduleStepsNames(reportSchedule.type) : [""]);
  const [isBackButtonVisible, setIsBackButtonVisible] = useState<boolean>(false);
  const [reportType, setReportType] = useState<ReportScheduleType>(reportSchedule ? reportSchedule.type : ReportScheduleType.UsageData);
  const [selectedFrequencyId, setSelectedFrequencyId] = useState(reportSchedule ? Number(reportSchedule.frequencyType + 1) : ReportScheduleFrequencyType.Daily + 1); // +1 because the value is 0 based
  const [scheduleName, setScheduleName] = useState(reportSchedule ? reportSchedule.name : "");
  const [timeValue, setTimeValue] = useState(getTimeStringFromReport(reportSchedule));
  const [selectedDaysOfTheWeek, setSelectedDaysOfTheWeek] = useState(getSelectedDaysOfTheWeek(reportSchedule));
  const [selectedDayOfTheMonthId, setSelectedDayOfTheMonthId] = useState(getSelectedDayOfTheMonth(reportSchedule));
  const [selectedMonthId, setSelectedMonthId] = useState(getSelectedMonthId(reportSchedule));
  const [nextEnabled, setNextEnabled] = useState(false);
  const [initialAccountSelectionProps] = useState({
    dialogStep: dialogStep,
    initialIncludedAccountIds: reportSchedule?.includedBccAccountIds,
    initialExcludedAccountIds: reportSchedule?.excludedBccAccountIds,
  });
  const { activeStep, displayedAccounts, selectedCount, selectedPage, allChecked, allCheckedByUser, includedAccountIds, excludedAccountIds, setActiveStep, onAllChecked, onItemSelected, onItemChecked, onPageSizeChanged, onPageNumberChange } = useAccountsSelection({ dialogStep, initialAccountSelectionProps });

  function onReportTypeChange(event: ChangeEvent<HTMLInputElement>): void {
    setReportType(Number(event.target.value));
  }

  function onFrequencyChange(id: number): void {
    setSelectedFrequencyId(id);
  }

  function onTimeValueChanged(event: ChangeEvent<HTMLInputElement>): void {
    setTimeValue(event.target.value);
  }

  function onDayOfTheMonthChanged(id: number): void {
    setSelectedDayOfTheMonthId(id);
  }

  function onMonthChanged(id: number): void {
    setSelectedMonthId(id);
  }

  function onDaysOfTheWeekChanged(days: string[]): void {
    const selectedDaysCopy = [...selectedDaysOfTheWeek];
    selectedDaysCopy.forEach(day => {
      if (days.indexOf(day.value) !== -1) {
        day.isSelected = true;
      } else {
        day.isSelected = false;
      }
    });

    setSelectedDaysOfTheWeek(selectedDaysCopy);
  }

  function getStepContent(step: number) {
    const stepName = dialogStepsNames[step];
    switch (stepName) {
      case TypeAndFrequencyStepLabel:
        return <TypeAndFrequencyStep reportType={reportType} onReportTypeChange={onReportTypeChange} selectedFrequencyId={selectedFrequencyId} onFrequencyChange={onFrequencyChange} timeValue={timeValue} onTimeValueChanged={onTimeValueChanged} selectedDaysOfTheWeek={selectedDaysOfTheWeek} onDaysOfTheWeekChanged={onDaysOfTheWeekChanged} selectedDayOfTheMonthId={selectedDayOfTheMonthId} onDayOfTheMonthChanged={onDayOfTheMonthChanged} selectedMonthId={selectedMonthId} onSelectedMonthChanged={onMonthChanged} />;
      case AccountsStepLabel:
        return <AccountsStep displayedAccounts={displayedAccounts} allChecked={allChecked} selectedCount={selectedCount} selectedPage={selectedPage} onAllChecked={onAllChecked} onItemSelected={onItemSelected} onItemChecked={onItemChecked} onPageSizeChanged={onPageSizeChanged} onPageNumberChange={onPageNumberChange} />;
      case EmailRecipientStepLabel:
        return <EmailRecipientStep reportType={reportType} isEditMode={reportSchedule !== undefined} />;
      default:
        break;
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleCancel = () => {
    onCancel();
  };

  const createReportSchedule = () => {
    const id = reportSchedule ? reportSchedule.id : undefined;
    const { timeValueHour, timeValueMinutes } = getHourAndMinutes(timeValue);
    const reportingEnabled = reportSchedule ? reportSchedule.reportingEnabled : true;

    let includedIds: number[] = [];
    let excludedIds: number[] = [];
    if (reportType === ReportScheduleType.UserBillingList) {
      // only usage billing list can have inclusions/exclusions, this is needed because the user can change the report type
      // from ReportScheduleType.UserBillingList to ReportScheduleType.UsageData
      includedIds = allCheckedByUser ? [0] : includedAccountIds;
      excludedIds = allCheckedByUser ? excludedAccountIds : [];
    }

    let status = reportSchedule?.status;
    if (reportSchedule?.id) {
      // status must not be set during edit
      status = undefined;
    }

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const lastExportDateUtc = reportSchedule?.lastExportDateUtc;
    let schedule: ReportScheduleBase | undefined;
    switch (selectedFrequencyId - 1) {
      case ReportScheduleFrequencyType.Daily:
        schedule = new DailyReportSchedule(scheduleName, reportType, timeValueHour, timeValueMinutes, timeZone, includedIds, excludedIds, id, undefined, status, reportingEnabled, lastExportDateUtc);
        break;
      case ReportScheduleFrequencyType.Weekly: {
        const daysOfTheWeekBitmask = selectedDaysOfTheWeek.filter(day => day.isSelected).reduce((bitmask, day) => bitmask | day.id, 0);
        schedule = new WeeklyReportSchedule(scheduleName, reportType, timeValueHour, timeValueMinutes, daysOfTheWeekBitmask, timeZone, includedIds, excludedIds, id, undefined, status, reportingEnabled, lastExportDateUtc);
        break;
      }
      case ReportScheduleFrequencyType.Monthly: {
        const monthlySelectedDayOfTheMonthBitmask = DaysOfTheMonth[selectedDayOfTheMonthId - 1].id;
        schedule = new MonthlyReportSchedule(scheduleName, reportType, timeValueHour, timeValueMinutes, monthlySelectedDayOfTheMonthBitmask, timeZone, includedIds, excludedIds, id, undefined, status, reportingEnabled, lastExportDateUtc);
        break;
      }
      case ReportScheduleFrequencyType.Annually: {
        const annuallySelectedDayOfTheMonthBitmask = DaysOfTheMonth[selectedDayOfTheMonthId - 1].id;
        const selectedMonthBitmask = DisplayedMonths[selectedMonthId - 1].id;
        schedule = new AnnualReportSchedule(scheduleName, reportType, timeValueHour, timeValueMinutes, selectedMonthBitmask, annuallySelectedDayOfTheMonthBitmask, timeZone, includedIds, excludedIds, id, undefined, status, reportingEnabled, lastExportDateUtc);
        break;
      }
      default:
        throw new Error("Unsupported frequency selected!");
    }
    return schedule;
  };

  const handleNext = () => {
    if (activeStep === dialogStepsNames.length - 1) {
      onSubmit(createReportSchedule());
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const onNameChanged = (newValue: string) => {
    setScheduleName(newValue);
  };

  useEffect(() => {
    setDialogStepsNames(getAddScheduleStepsNames(reportType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType]);

  useEffect(() => {
    const { isBackVisible, nextButtonLabel } = getButtonStates(reportSchedule, activeStep, dialogStepsNames.length);
    setIsBackButtonVisible(isBackVisible);
    setNextButtonLabel(nextButtonLabel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, dialogStepsNames]);

  useEffect(() => {
    if (selectedCount >= 1) {
      setNextEnabled(true);
      return;
    }
    const stepName = dialogStepsNames[activeStep];
    if (stepName === AccountsStepLabel) {
      setNextEnabled(false);
    } else {
      setNextEnabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogStepsNames, selectedCount, activeStep]);

  return (
    <Dialog className="addEditScheduleDialog" disableEscapeKeyDown={isActionInProgress} data-testid="addEditScheduleDialog" open={showDialog} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)} maxWidth={false}>
      <div className="addEditSchedule">
        <DialogTitle data-testid="addEditScheduleDialogTitle" id="alert-dialog-title">
          {title}
        </DialogTitle>
        <div>
          <TextField style={{ width: "93%", margin: 20 }} data-testid="addEditScheduleName" placeholder="New Schedule" value={scheduleName} onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => onNameChanged(ev.target.value)} />
        </div>
        <DialogStepper useElevation={false} stepsNames={dialogStepsNames} activeStep={activeStep} boldActiveStep={true} />
        <DialogContent>
          <div className="DialogContentDiv" style={{ width: 620 }}>
            <Grid item>{getStepContent(activeStep)}</Grid>
          </div>
        </DialogContent>
        <DialogActions style={{ paddingTop: "18px", paddingBottom: "18px" }}>
          <div style={{ display: "flex", gap: "8px" }}>
            <Button data-testid="cancelAddScheduleBtn" variant="text" size="large" disabled={isActionInProgress} onClick={handleCancel}>
              CANCEL
            </Button>
            {isBackButtonVisible && (
              <Button data-testid="backAddScheduleBtn" variant="text" size="large" disabled={isActionInProgress} onClick={handleBack}>
                BACK
              </Button>
            )}
            <Button data-testid="confirmNextAddScheduleBtn" disabled={!nextEnabled} loadingPosition="start" size="large" type={"submit"} color="primary" isLoading={isActionInProgress} onClick={() => handleNext()}>
              {nextButtonLabel}
            </Button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default AddEditScheduleDialog;

export function getAddScheduleStepsNames(reportType: ReportScheduleType): string[] {
  let steps: string[] = [];
  switch (reportType) {
    case ReportScheduleType.UsageData: {
      steps = [TypeAndFrequencyStepLabel, EmailRecipientStepLabel];
      break;
    }
    case ReportScheduleType.UserBillingList: {
      steps = [TypeAndFrequencyStepLabel, AccountsStepLabel, EmailRecipientStepLabel];
      break;
    }
    default:
      steps = [];
  }

  return steps;
}

export function getButtonStates(reportSchedule: ReportScheduleBase | undefined, activeStep: number, dialogStepsLength: number) {
  let isBackVisible = true;
  let nextButtonLabel = reportSchedule?.id ? "UPDATE" : "ADD";

  if (activeStep !== dialogStepsLength - 1) {
    nextButtonLabel = "NEXT";
  }

  if (activeStep === 0) {
    isBackVisible = false;
  }

  return { isBackVisible, nextButtonLabel };
}

export function getSelectedDayOfTheMonth(reportSchedule: ReportScheduleBase | undefined): number {
  if (reportSchedule && (reportSchedule.frequencyType === ReportScheduleFrequencyType.Monthly || reportSchedule.frequencyType === ReportScheduleFrequencyType.Annually)) {
    const monthlySchedule = reportSchedule as MonthlyReportSchedule;
    const dayOfMonthBitmask = monthlySchedule.daysOfMonth;
    const dayId = Object.keys(ReportScheduleDaysOfMonthBitmask).find(key => ReportScheduleDaysOfMonthBitmask[key as keyof typeof ReportScheduleDaysOfMonthBitmask] === dayOfMonthBitmask);

    return DaysOfTheMonth.findIndex(day => ReportScheduleDaysOfMonthBitmask[day.id] === dayId) + 1;
  }

  return ReportScheduleDaysOfMonthBitmask.First;
}

function getSelectedMonthId(reportSchedule: ReportScheduleBase | undefined): number {
  if (reportSchedule && reportSchedule.frequencyType === ReportScheduleFrequencyType.Annually) {
    const annualSchedule = reportSchedule as AnnualReportSchedule;
    const monthBitmask = annualSchedule.month;
    const monthId = Object.keys(ReportScheduleMonthBitmask).find(key => ReportScheduleMonthBitmask[key as keyof typeof ReportScheduleMonthBitmask] === monthBitmask);
    return DisplayedMonths.findIndex(month => month.value === monthId) + 1;
  }

  return ReportScheduleMonthBitmask.January;
}

export function getSelectedDaysOfTheWeek(reportSchedule: ReportScheduleBase | undefined): IDayOfTheWeek[] {
  if (reportSchedule && reportSchedule.frequencyType === ReportScheduleFrequencyType.Weekly) {
    const weeklySchedule = reportSchedule as WeeklyReportSchedule;
    return [
      { id: ReportScheduleDaysOfWeekBitmask.Sunday, value: ReportScheduleDaysOfWeekBitmask[ReportScheduleDaysOfWeekBitmask.Sunday], isSelected: !!(weeklySchedule.daysOfWeek & ReportScheduleDaysOfWeekBitmask.Sunday) },
      { id: ReportScheduleDaysOfWeekBitmask.Monday, value: ReportScheduleDaysOfWeekBitmask[ReportScheduleDaysOfWeekBitmask.Monday], isSelected: !!(weeklySchedule.daysOfWeek & ReportScheduleDaysOfWeekBitmask.Monday) },
      { id: ReportScheduleDaysOfWeekBitmask.Tuesday, value: ReportScheduleDaysOfWeekBitmask[ReportScheduleDaysOfWeekBitmask.Tuesday], isSelected: !!(weeklySchedule.daysOfWeek & ReportScheduleDaysOfWeekBitmask.Tuesday) },
      { id: ReportScheduleDaysOfWeekBitmask.Wednesday, value: ReportScheduleDaysOfWeekBitmask[ReportScheduleDaysOfWeekBitmask.Wednesday], isSelected: !!(weeklySchedule.daysOfWeek & ReportScheduleDaysOfWeekBitmask.Wednesday) },
      { id: ReportScheduleDaysOfWeekBitmask.Thursday, value: ReportScheduleDaysOfWeekBitmask[ReportScheduleDaysOfWeekBitmask.Thursday], isSelected: !!(weeklySchedule.daysOfWeek & ReportScheduleDaysOfWeekBitmask.Thursday) },
      { id: ReportScheduleDaysOfWeekBitmask.Friday, value: ReportScheduleDaysOfWeekBitmask[ReportScheduleDaysOfWeekBitmask.Friday], isSelected: !!(weeklySchedule.daysOfWeek & ReportScheduleDaysOfWeekBitmask.Friday) },
      { id: ReportScheduleDaysOfWeekBitmask.Saturday, value: ReportScheduleDaysOfWeekBitmask[ReportScheduleDaysOfWeekBitmask.Saturday], isSelected: !!(weeklySchedule.daysOfWeek & ReportScheduleDaysOfWeekBitmask.Saturday) },
    ];
  }

  //new report, no day should be selected in this state
  return [
    { id: ReportScheduleDaysOfWeekBitmask.Sunday, value: ReportScheduleDaysOfWeekBitmask[ReportScheduleDaysOfWeekBitmask.Sunday], isSelected: false },
    { id: ReportScheduleDaysOfWeekBitmask.Monday, value: ReportScheduleDaysOfWeekBitmask[ReportScheduleDaysOfWeekBitmask.Monday], isSelected: false },
    { id: ReportScheduleDaysOfWeekBitmask.Tuesday, value: ReportScheduleDaysOfWeekBitmask[ReportScheduleDaysOfWeekBitmask.Tuesday], isSelected: false },
    { id: ReportScheduleDaysOfWeekBitmask.Wednesday, value: ReportScheduleDaysOfWeekBitmask[ReportScheduleDaysOfWeekBitmask.Wednesday], isSelected: false },
    { id: ReportScheduleDaysOfWeekBitmask.Thursday, value: ReportScheduleDaysOfWeekBitmask[ReportScheduleDaysOfWeekBitmask.Thursday], isSelected: false },
    { id: ReportScheduleDaysOfWeekBitmask.Friday, value: ReportScheduleDaysOfWeekBitmask[ReportScheduleDaysOfWeekBitmask.Friday], isSelected: false },
    { id: ReportScheduleDaysOfWeekBitmask.Saturday, value: ReportScheduleDaysOfWeekBitmask[ReportScheduleDaysOfWeekBitmask.Saturday], isSelected: false },
  ];
}

export function getHourAndMinutes(timeValue: string): { timeValueHour: number; timeValueMinutes: number } {
  const timeValueArray = timeValue.split(":");
  return { timeValueHour: Number(timeValueArray[0]), timeValueMinutes: Number(timeValueArray[1]) };
}

export function getTimeStringFromReport(reportSchedule: ReportScheduleBase | undefined): string {
  if (reportSchedule) {
    const hour = reportSchedule.timeOfDayHour.toString().padStart(2, "0");
    const minute = reportSchedule.timeOfDayMinute.toString().padStart(2, "0");
    return `${hour}:${minute}`;
  }

  return "00:00";
}
