import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@cuda-networks/bds-core";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import IAccount from "../../models/IAccount";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/store";
import { handleBackdropClick, truncate } from "../../utility";
import { CheckedState } from "../../models/CheckedState";
import { queueExportUsersRequestAction } from "../../actions/productActions";
import { ActionMessages, ActionTypes } from "../../actions/ActionTypes";
import { setSnackBarMessage } from "../../actions/generalActions";
import ActionMessageType from "../../models/ActionMessageType";
import AccountsList from "../Accounts/AccountsList";
import useAccountsSelection from "../Accounts/useAccountsSelection";

interface IExportUserBillingListDialogProps {
  onSubmit: () => void;
  onCancel: () => void;
  showDialog: boolean;
  isActionInProgress: boolean;
  dialogStep: number;
  title: string;
  selectedAccount: IAccount | undefined;
}

const ExportUserBillingListDialog: React.FC<IExportUserBillingListDialogProps> = ({ showDialog, dialogStep, onCancel, onSubmit, isActionInProgress, title, selectedAccount }) => {
  const loggedInUserEmail = useSelector((state: IAppState) => state.generalState.loggedUser?.email);
  const dispatch = useDispatch();
  const [nextButtonLabel, setNextButtonLabel] = useState("");
  const [isBackButtonVisible, setIsBackButtonVisible] = useState<boolean>(false);
  const [nextEnabled, setNextEnabled] = useState(false);

  const [minHeight, setMinHeight] = useState(600);
  const [width, setWidth] = useState(480);
  const widthForExportStep = 400;
  const minHeightForExportStep = 100;

  const { activeStep, displayedAccounts, selectedCount, selectedPage, allChecked, allCheckedByUser, includedAccountIds, excludedAccountIds, setActiveStep, setDisplayedAccounts, setAllChecked, setAllCheckedByUser, setIncludedAccountIds, setExcludedAccountIds, getDisplayedAccounts, onAllChecked, onItemSelected, onItemChecked, onPageSizeChanged, onPageNumberChange } = useAccountsSelection({ dialogStep, initialAccountSelectionProps: { dialogStep, initialIncludedAccountIds: [], initialExcludedAccountIds: [] } });

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <AccountsList height={"680px"} width={"525px"} pageSize={10} displayedAccounts={displayedAccounts} allChecked={allChecked} selectedCount={selectedCount} selectedPage={selectedPage} onAllChecked={onAllChecked} onItemSelected={onItemSelected} onItemChecked={onItemChecked} onPageSizeChanged={onPageSizeChanged} onPageNumberChange={onPageNumberChange} />;
      case 1:
        return (
          <div style={{ width: width, minHeight: minHeight }} data-testid="exportUserBillingListConfirmationMessage">
            <p>
              Pressing export will send user billing information with <b>{truncate(selectedAccount?.name ?? "", 20)}</b> to <b>{loggedInUserEmail}</b>. It may take up to an hour for the email to be received.
            </p>
          </div>
        );
      default:
        break;
    }
  }

  function resetDialogData() {
    setIncludedAccountIds([]);
    setExcludedAccountIds([]);
    setAllChecked(CheckedState.Unchecked);
    setDisplayedAccounts(getDisplayedAccounts());
    setAllCheckedByUser(false);
  }

  useEffect(() => {
    setNextButtonLabel(getNextButtonText(activeStep));
    setIsBackButtonVisible(activeStep !== 0);
  }, [activeStep]);

  useEffect(() => {
    if (selectedCount >= 1) {
      setNextEnabled(true);
      return;
    }

    setNextEnabled(false);
  }, [selectedCount]);

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleCancel = () => {
    resetDialogData();
    onCancel();
  };

  const exportBillingUsers = () =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(queueExportUsersRequestAction(selectedAccount?.id, includedAccountIds, excludedAccountIds, allCheckedByUser));
      resolve(result);
    });

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        // resize the container to match the desired size for the next step
        setMinHeight(minHeightForExportStep);
        setWidth(widthForExportStep);
        break;
      case 1:
        exportBillingUsers().then(result => {
          if (result) {
            dispatch(
              setSnackBarMessage({
                message: ActionMessages[ActionTypes.ExportBillingUsers].infoMessage,
                type: ActionMessageType.Info,
              }),
            );
          }
        });
        resetDialogData();
        onSubmit();
        break;
    }
  };

  return (
    <Dialog className="exportBillingListDialog" disableEscapeKeyDown={isActionInProgress} data-testid="exportBillingListDialog" open={showDialog} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)} maxWidth={false}>
      <div className="exportBillingList">
        <DialogTitle data-testid="exportBillingListDialogTitle" id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <div className="DialogContentDiv">
            <Grid item xs={12} style={{ minHeight: minHeight }}>
              {getStepContent(activeStep)}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions style={{ paddingTop: "18px", paddingBottom: "18px" }}>
          <Button data-testid="cancelExportBillingListBtn" variant="text" size="large" disabled={isActionInProgress} onClick={handleCancel}>
            CANCEL
          </Button>
          {isBackButtonVisible && (
            <Button data-testid="backExportBillingListBtn" variant="text" size="large" disabled={isActionInProgress} onClick={handleBack}>
              BACK
            </Button>
          )}
          <Button data-testid="confirmNextExportBillingListBtn" disabled={!nextEnabled} loadingPosition="start" size="large" type={"submit"} color="primary" isLoading={isActionInProgress} onClick={() => handleNext()}>
            {nextButtonLabel}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ExportUserBillingListDialog;

export function getNextButtonText(activeStep: number): string {
  if (activeStep === 1) {
    return "EXPORT";
  }
  return "CONTINUE";
}
