import { ReportScheduleDaysOfWeekBitmask } from "../ReportScheduleDaysOfWeekBitmask";
import { ReportScheduleFrequencyType } from "../ReportScheduleFrequencyType";
import { ReportScheduleStatus } from "../ReportScheduleStatus";
import { ReportScheduleType } from "../ReportScheduleType";
import { ReportScheduleBase } from "./ReportScheduleBase";

class WeeklyReportSchedule extends ReportScheduleBase {
  frequencyType: ReportScheduleFrequencyType = ReportScheduleFrequencyType.Weekly;
  daysOfWeek: ReportScheduleDaysOfWeekBitmask;

  constructor(name: string, type: ReportScheduleType, timeOfDayHour: number, timeOfDayMinute: number, daysOfWeek: ReportScheduleDaysOfWeekBitmask, userTimeZone: string, includedBccAccountIds: number[] = [], excludedBccAccountIds: number[] = [], id?: number, numberOfAccounts: number = 0, status?: ReportScheduleStatus, reportingEnabled: boolean = true, lastExportDateUtc?: Date | null) {
    super(name, type, timeOfDayHour, timeOfDayMinute, userTimeZone, includedBccAccountIds, excludedBccAccountIds, id, numberOfAccounts, status, reportingEnabled, lastExportDateUtc);
    this.daysOfWeek = daysOfWeek;
  }
}
export default WeeklyReportSchedule;
