import React, { ChangeEvent } from "react";
import { DaysOfTheMonth } from "../../AddEditScheduleDialog";
import { Typography, Grid } from "@cuda-networks/bds-core";
import DailyFrequency from "./DailyFrequency";
import DropDown from "../../../../DropDown";

interface IMonthlyFrequencyProps {
  timeValue: string;
  onTimeValueChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedDayOfTheMonthId: number;
  onDayOfTheMonthChanged: (id: number) => void;
}

const MonthlyFrequency: React.FC<IMonthlyFrequencyProps> = ({ timeValue, onTimeValueChanged, selectedDayOfTheMonthId, onDayOfTheMonthChanged }) => {
  return (
    <Grid item xs={12} container direction="column">
      <Grid item container>
        <Grid item style={{ minWidth: 150 }}>
          <div data-testid="dayOfTheMonthDropdown" style={{ marginRight: 20 }}>
            <Typography style={{ marginBottom: 10 }}>Day of the Month</Typography>
            <DropDown size="medium" options={DaysOfTheMonth.map(day => day.value)} selectedOptionId={selectedDayOfTheMonthId} error={""} handleChange={onDayOfTheMonthChanged} disabled={false} label={""} />
          </div>
        </Grid>
        <Grid item>
          <DailyFrequency timeValue={timeValue} onTimeValueChanged={onTimeValueChanged} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MonthlyFrequency;
