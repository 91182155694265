import React from "react";
import AccountsList from "../../AccountsList";
import IAccount from "../../../../models/IAccount";
import { CheckedState } from "../../../../models/CheckedState";

interface IAccountsStepProps {
  displayedAccounts: IAccount[];
  allChecked: CheckedState;
  selectedCount: number;
  selectedPage: number;
  onAllChecked: (s: boolean) => void;
  onItemSelected: (account: any) => void;
  onItemChecked: (account: any, checked: boolean) => void;
  onPageSizeChanged: (size: number) => void;
  onPageNumberChange: (page: number) => void;
}

const AccountsStep: React.FC<IAccountsStepProps> = ({ displayedAccounts, allChecked, selectedCount, selectedPage, onAllChecked, onItemSelected, onItemChecked, onPageSizeChanged, onPageNumberChange }) => {
  return <AccountsList pageSize={5} displayedAccounts={displayedAccounts} allChecked={allChecked} selectedCount={selectedCount} selectedPage={selectedPage} onAllChecked={onAllChecked} onItemSelected={onItemSelected} onItemChecked={onItemChecked} onPageSizeChanged={onPageSizeChanged} onPageNumberChange={onPageNumberChange} height="auto" width="auto" />;
};

export default AccountsStep;
