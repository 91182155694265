export enum ReportScheduleDaysOfMonthBitmask {
  None = 0, // none selected
  First = 1 << 0, // 1st day of the month
  Second = 1 << 1, // 2nd day of the month
  Third = 1 << 2, // 3rd day of the month
  Fourth = 1 << 3, // 4th day of the month
  Fifth = 1 << 4, // 5th day of the month
  Sixth = 1 << 5, // 6th day of the month
  Seventh = 1 << 6, // 7th day of the month
  Eighth = 1 << 7, // 8th day of the month
  Ninth = 1 << 8, // 9th day of the month
  Tenth = 1 << 9, // 10th day of the month
  Eleventh = 1 << 10, // 11th day of the month
  Twelfth = 1 << 11, // 12th day of the month
  Thirteenth = 1 << 12, // 13th day of the month
  Fourteenth = 1 << 13, // 14th day of the month
  Fifteenth = 1 << 14, // 15th day of the month
  Sixteenth = 1 << 15, // 16th day of the month
  Seventeenth = 1 << 16, // 17th day of the month
  Eighteenth = 1 << 17, // 18th day of the month
  Nineteenth = 1 << 18, // 19th day of the month
  Twentieth = 1 << 19, // 20th day of the month
  TwentyFirst = 1 << 20, // 21st day of the month
  TwentySecond = 1 << 21, // 22nd day of the month
  TwentyThird = 1 << 22, // 23rd day of the month
  TwentyFourth = 1 << 23, // 24th day of the month
  TwentyFifth = 1 << 24, // 25th day of the month
  TwentySixth = 1 << 25, // 26th day of the month
  TwentySeventh = 1 << 26, // 27th day of the month
  TwentyEighth = 1 << 27, // 28th day of the month
  TwentyNinth = 1 << 28, // 29th day of the month
  Thirtieth = 1 << 29, // 30th day of the month
  ThirtyFirst = 1 << 30, // 31st day of the month
  //  cannot use shift operator for 31st day of the month
  LastDayOfMonth = 2147483648, // last day of the month
}
