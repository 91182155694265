import { useState, useEffect } from "react";
import IAccount from "../../models/IAccount";
import { CheckedState } from "../../models/CheckedState";
import MspType from "../../models/MspType";
import { dynamicSort } from "../../utility";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/store";

interface IAccountSelectionProps {
  dialogStep: number;
  initialAccountSelectionProps: IInitialAccountSelectionProps;
}

interface IInitialAccountSelectionProps {
  dialogStep: number;
  initialIncludedAccountIds?: number[];
  initialExcludedAccountIds?: number[];
}

const useAccountsSelection = ({ dialogStep, initialAccountSelectionProps }: IAccountSelectionProps) => {
  const items = useSelector((state: IAppState) => state.accountState.accountsNames);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const [activeStep, setActiveStep] = useState(dialogStep);
  const [displayedAccounts, setDisplayedAccounts] = useState(getDisplayedAccounts());
  const [allChecked, setAllChecked] = useState<CheckedState>(CheckedState.Unchecked);
  const [allCheckedByUser, setAllCheckedByUser] = useState(false);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [selectedCount, setSelectedCount] = useState(0);
  const [includedAccountIds, setIncludedAccountIds] = useState<number[]>([]);
  const [excludedAccountIds, setExcludedAccountIds] = useState<number[]>([]);

  useEffect(() => {
    setDisplayedAccounts(getDisplayedAccounts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, selectedAccount]);

  useEffect(() => {
    if (!initialAccountSelectionProps?.initialIncludedAccountIds || !initialAccountSelectionProps?.initialExcludedAccountIds) {
      return;
    }
    setIncludedAccountIds([]);
    setExcludedAccountIds([]);
    const newIncludedAccountIds: number[] = [];
    const newExcludedAccountIds: number[] = [];
    initialAccountSelectionProps.initialIncludedAccountIds.forEach((accountId: number) => {
      if (accountId === 0) {
        items.forEach(item => {
          onItemChecked(item, true);
          newIncludedAccountIds.push(item.id);
        });
      } else {
        const item = items.find(item => item.id === accountId) as IAccount;
        if (item) {
          onItemChecked(item, true);
          newIncludedAccountIds.push(accountId);
        }
      }
    });

    initialAccountSelectionProps.initialExcludedAccountIds.forEach((accountId: number) => {
      const item = items.find(item => item.id === accountId) as IAccount;
      if (item) {
        onItemChecked(item, false);
        newExcludedAccountIds.push(accountId);
      }
    });
    if (newIncludedAccountIds.length === items.length) {
      const state = newExcludedAccountIds.length === 0 ? CheckedState.Checked : CheckedState.Indeterminate;
      setAllChecked(state);
      setAllCheckedByUser(newExcludedAccountIds.length === 0);
    } else {
      setAllCheckedByUser(false);
      if (newIncludedAccountIds.length > 0) {
        setAllChecked(CheckedState.Indeterminate);
      } else {
        setAllChecked(CheckedState.Unchecked);
      }
    }
    setIncludedAccountIds(newIncludedAccountIds);
    setExcludedAccountIds(newExcludedAccountIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getDisplayedAccounts() {
    return items
      .filter(item => {
        if (item.type === MspType.Customer && item.closestParentId === selectedAccount?.id) {
          return true;
        }
        return false;
      })
      .map(item => ({ ...item }))
      .sort(dynamicSort("name"));
  }

  const onAllChecked = (checked: boolean) => {
    //reset both arrays - at this time all accounts are either selected or not
    setIncludedAccountIds([]);
    setExcludedAccountIds([]);

    setAllCheckedByUser(checked);

    const checkedState = checked ? CheckedState.Checked : CheckedState.Unchecked;
    setAllChecked(checkedState);
    setSelectedCount(checked ? displayedAccounts.length : 0);
    const newDisplayedAccounts = displayedAccounts.map(account => {
      account.checkedState = checkedState;
      return account;
    });

    setDisplayedAccounts(newDisplayedAccounts);
  };

  const onItemSelected = () => {
    //we cannot select items - only check/uncheck them
  };

  function updateExcludedAccounts(itemId: number, checked: boolean) {
    if (checked) {
      //we added back an account that had been checked => remove it from the exclusions list
      const newExcludedAccountIds = [...excludedAccountIds];
      const itemIndex = newExcludedAccountIds.indexOf(itemId);
      newExcludedAccountIds.splice(itemIndex, 1);
      setExcludedAccountIds(newExcludedAccountIds);
    } else if (!excludedAccountIds.includes(itemId)) {
      //we removed an account when all had been checked => add it to exclusions list
      setExcludedAccountIds([...excludedAccountIds, itemId]);
    }
  }

  function updateIncludedAccounts(itemId: number, checked: boolean) {
    if (checked) {
      //we added an account => add to inclusions list
      if (!includedAccountIds.includes(itemId)) {
        setIncludedAccountIds([...includedAccountIds, itemId]);
      }
    } else {
      //we removed an account => remove from inclusions list
      const newIncludedAccountIds = includedAccountIds.filter(id => id !== itemId);
      setIncludedAccountIds(newIncludedAccountIds);
    }
  }

  function syncInclusionAndExclusionsList(itemId: number, checked: boolean) {
    //if "Select All Accounts" is or was previously checked by user
    if (allCheckedByUser) {
      updateExcludedAccounts(itemId, checked);
      return;
    }

    //if "Select All Accounts" is not and was not checked by user
    updateIncludedAccounts(itemId, checked);
  }

  function updateSelectedCount(checked: boolean) {
    if (checked) {
      if (selectedCount + 1 === displayedAccounts.length) {
        setAllChecked(CheckedState.Checked);
      } else if (allChecked === CheckedState.Unchecked) {
        setAllChecked(CheckedState.Indeterminate);
      }

      setSelectedCount(previousCount => previousCount + 1);
      return;
    }

    if (selectedCount - 1 === 0) {
      setAllChecked(CheckedState.Unchecked);
    } else if (allChecked === CheckedState.Checked) {
      setAllChecked(CheckedState.Indeterminate);
    }

    setSelectedCount(previousCount => previousCount - 1);
  }

  function updateDisplayedAccounts(itemId: number, checked: boolean) {
    const newDisplayedAccounts = displayedAccounts.map(account => {
      if (account?.id === itemId) {
        account.checkedState = checked ? CheckedState.Checked : CheckedState.Unchecked;
      }
      return account;
    });

    setDisplayedAccounts(newDisplayedAccounts);
  }

  const onItemChecked = (item: IAccount, checked: boolean): void => {
    syncInclusionAndExclusionsList(item.id, checked);
    updateSelectedCount(checked);
    updateDisplayedAccounts(item.id, checked);
  };

  const onPageSizeChanged = () => {
    //page size always remains the same
  };

  const onPageNumberChange = (value: number): void => {
    setSelectedPage(value);
  };

  return {
    activeStep,
    displayedAccounts,
    selectedCount,
    selectedPage,
    allChecked,
    allCheckedByUser,
    includedAccountIds,
    excludedAccountIds,
    setActiveStep,
    setDisplayedAccounts,
    setAllChecked,
    setAllCheckedByUser,
    setIncludedAccountIds,
    setExcludedAccountIds,
    getDisplayedAccounts,
    onAllChecked,
    onItemSelected,
    onItemChecked,
    onPageSizeChanged,
    onPageNumberChange,
  };
};

export default useAccountsSelection;
