import React, { ChangeEvent, useEffect, useState } from "react";
import { IDayOfTheWeek } from "../../AddEditScheduleDialog";
import { CheckedState } from "../../../../../models/CheckedState";
import { Typography, Grid, SelectWrapper, Select, MenuItem, Checkbox } from "@cuda-networks/bds-core";
import CheckBoxWithIndeterminateState from "../../../../CheckBoxWithIndeterminateState";
import DailyFrequency from "./DailyFrequency";

interface IWeeklyFrequencyProps {
  timeValue: string;
  onTimeValueChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedDaysOfTheWeek: IDayOfTheWeek[];
  onDaysOfTheWeekChanged: (days: string[]) => void;
}

const selectAllLabel = "Select All";
const selectDaysPlaceholderLabel = "Select Day(s)";
const allDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const allPlaceholderLabel = "All";

const WeeklyFrequency: React.FC<IWeeklyFrequencyProps> = ({ timeValue, onTimeValueChanged, selectedDaysOfTheWeek, onDaysOfTheWeekChanged }) => {
  const [displayValue, setDisplayValue] = useState([selectDaysPlaceholderLabel]);
  const [allDaysCheckedState, setAllDaysCheckedState] = useState(CheckedState.Unchecked);

  useEffect(() => {
    handleSelectAllCheckboxState(selectedDaysOfTheWeek.filter(day => day.isSelected).length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDaysOfTheWeek]);

  useEffect(() => {
    const selectedDays = selectedDaysOfTheWeek.filter(day => day.isSelected).map(day => day.value);
    handleSelectAllCheckboxState(selectedDays.length);
    onDisplayedValueChanged(selectedDays);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectAllCheckboxState = (currentSelectedDaysLength: number) => {
    if (currentSelectedDaysLength === 7) {
      setAllDaysCheckedState(CheckedState.Checked);
      return;
    }

    if (currentSelectedDaysLength === 0) {
      setAllDaysCheckedState(CheckedState.Unchecked);
      return;
    }

    setAllDaysCheckedState(CheckedState.Indeterminate);
  };

  const onDisplayedValueChanged: any = (selected: any) => {
    const selectedDays = selected.filter((value: string) => value !== selectDaysPlaceholderLabel);

    //When "Select All" is clicked, it is handled in onSelectAllDaysClick
    if (selectedDays.find((day: string) => day === selectAllLabel)) {
      return;
    }

    handleSelectAllCheckboxState(selectedDays.length);

    if (selectedDays.length > 0) {
      setDisplayValue(selectedDays);
    } else {
      setDisplayValue([selectDaysPlaceholderLabel]);
    }
    onDaysOfTheWeekChanged(selectedDays);
  };

  function onAllDaysSelectedChanged(checked: boolean): void {
    if (checked) {
      onDisplayedValueChanged(allDays);
      setAllDaysCheckedState(CheckedState.Checked);
      return;
    }

    onDaysOfTheWeekChanged([]);
    onDisplayedValueChanged([]);
    setAllDaysCheckedState(CheckedState.Unchecked);
  }

  function onSelectAllDaysClick() {
    if (allDaysCheckedState === CheckedState.Checked) {
      onAllDaysSelectedChanged(false);
      return;
    }

    onAllDaysSelectedChanged(true);
  }

  return (
    <Grid item xs={12} container direction="column">
      <Grid item container>
        <Grid item style={{ minWidth: 150 }}>
          <div data-testid="dayOfTheWeekDropdown" style={{ marginRight: 20 }}>
            <Typography style={{ marginBottom: 10 }}>Day of the Week</Typography>
            <SelectWrapper size={"medium"} style={{ width: 150 }}>
              <Select
                disabled={false}
                multiple={true}
                onChange={(e: any) => {
                  onDisplayedValueChanged(e.target.value);
                }}
                value={displayValue}
                renderValue={getRenderedDaysOfTheWeekValue}
              >
                <MenuItem key={0} value={selectAllLabel} disabled={false} size={"small"} onClick={onSelectAllDaysClick}>
                  <CheckBoxWithIndeterminateState checkedState={allDaysCheckedState} onCheck={() => {}} disabled={false} size={"small"} />
                  {selectAllLabel}
                </MenuItem>
                {selectedDaysOfTheWeek.map(({ id, value, isSelected }) => {
                  return (
                    <MenuItem key={id} value={value} disabled={false} size={"small"}>
                      <Checkbox checked={isSelected} />
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
            </SelectWrapper>
          </div>
        </Grid>
        <Grid item>
          <DailyFrequency timeValue={timeValue} onTimeValueChanged={onTimeValueChanged} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WeeklyFrequency;

export function getRenderedDaysOfTheWeekValue(selected: any) {
  if (selected.length === 0) {
    return selectDaysPlaceholderLabel;
  }
  if (selected.length === 7) {
    return allPlaceholderLabel;
  }

  const selectedString = selected.join(", ");
  return selectedString.length > 16 ? selectedString.substring(0, 16) + "..." : selectedString;
}
