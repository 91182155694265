import { Grid, RadioButton, Typography } from "@cuda-networks/bds-core";
import React, { ChangeEvent } from "react";
import DropDown from "../../../DropDown";
import { Frequencies, IDayOfTheWeek, ReportTypes } from "../AddEditScheduleDialog";
import { ReportScheduleType } from "../../../../models/ReportSchedule/ReportScheduleType";
import WeeklyFrequency from "./Frequencies/WeeklyFrequency";
import DailyFrequency from "./Frequencies/DailyFrequency";
import MonthlyFrequency from "./Frequencies/MonthlyFrequency";
import { ReportScheduleFrequencyType } from "../../../../models/ReportSchedule/ReportScheduleFrequencyType";
import AnnuallyFrequency from "./Frequencies/AnnuallyFrequency";

interface ITypeAndFrequencyStepProps {
  reportType: ReportScheduleType;
  onReportTypeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedFrequencyId: number;
  onFrequencyChange: (id: number) => void;
  timeValue: string;
  onTimeValueChanged: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedDaysOfTheWeek: IDayOfTheWeek[];
  onDaysOfTheWeekChanged: (days: string[]) => void;
  selectedDayOfTheMonthId: number;
  onDayOfTheMonthChanged: (id: number) => void;
  selectedMonthId: number;
  onSelectedMonthChanged: (id: number) => void;
}

const TypeAndFrequencyStep: React.FC<ITypeAndFrequencyStepProps> = ({ reportType, onReportTypeChange, selectedFrequencyId, onFrequencyChange, timeValue, onTimeValueChanged, selectedDaysOfTheWeek, onDaysOfTheWeekChanged, selectedDayOfTheMonthId, onDayOfTheMonthChanged, selectedMonthId, onSelectedMonthChanged }) => {
  function getDateTimeInputs(selectedFrequencyId: number) {
    switch (selectedFrequencyId - 1) {
      case ReportScheduleFrequencyType.Daily:
        return (
          <div style={{ marginLeft: 10 }} data-testid="dailyFrequencyTimeOfDayPicker">
            <DailyFrequency timeValue={timeValue} onTimeValueChanged={onTimeValueChanged} />
          </div>
        );
      case ReportScheduleFrequencyType.Weekly:
        return (
          <div style={{ marginLeft: 10 }} data-testid="weeklyFrequencyPickers">
            <WeeklyFrequency timeValue={timeValue} onTimeValueChanged={onTimeValueChanged} selectedDaysOfTheWeek={selectedDaysOfTheWeek} onDaysOfTheWeekChanged={onDaysOfTheWeekChanged} />
          </div>
        );
      case ReportScheduleFrequencyType.Monthly:
        return (
          <div style={{ marginLeft: 10 }} data-testid="monthlyFrequencyPickers">
            <MonthlyFrequency timeValue={timeValue} onTimeValueChanged={onTimeValueChanged} selectedDayOfTheMonthId={selectedDayOfTheMonthId} onDayOfTheMonthChanged={onDayOfTheMonthChanged} />
          </div>
        );
      case ReportScheduleFrequencyType.Annually:
        return (
          <div style={{ marginLeft: 10 }} data-testid="annuallyFrequencyPickers">
            <AnnuallyFrequency timeValue={timeValue} onTimeValueChanged={onTimeValueChanged} selectedDayOfTheMonthId={selectedDayOfTheMonthId} onDayOfTheMonthChanged={onDayOfTheMonthChanged} selectedMonthId={selectedMonthId} onMonthChanged={onSelectedMonthChanged} />
          </div>
        );
      default:
        break;
    }
  }

  return (
    <div data-testid="addEditScheduleTypeAndFrequencyStep">
      <Grid item container>
        <Grid container item>
          <Grid style={{ boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 0px 0px", marginBottom: "7px" }} item container direction="column">
            <Typography style={{ fontWeight: "bold" }}>Type</Typography>
            <Grid item container>
              <Grid item>
                <RadioButton data-testid="usageDataRadioBtn" label={ReportTypes[ReportScheduleType.UsageData].value} value={ReportScheduleType.UsageData} checked={reportType === ReportScheduleType.UsageData} onChange={onReportTypeChange} />
              </Grid>
              <Grid item>
                <RadioButton data-testid="userBillingListRadioBtn" label={ReportTypes[ReportScheduleType.UserBillingList].value} value={ReportScheduleType.UserBillingList} checked={reportType === ReportScheduleType.UserBillingList} onChange={onReportTypeChange} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="column">
            <Grid item container>
              <Grid item style={{ minWidth: 150 }}>
                <div data-testid="addScheduleFrequencyDropdown">
                  <Typography style={{ marginBottom: 10 }}>Frequency</Typography>
                  <DropDown size="medium" options={Frequencies.map(frequency => frequency.value)} selectedOptionId={selectedFrequencyId} error={""} handleChange={onFrequencyChange} disabled={false} label={""} />
                </div>
              </Grid>
              <Grid item>{getDateTimeInputs(selectedFrequencyId)}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default TypeAndFrequencyStep;
