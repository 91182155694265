import { CancelToken } from "axios";
import IAccount from "../models/IAccount";
import IUser from "../models/IUser";
import { UserRoleValues } from "../models/UserRole";
import { getFinalProduct } from "../utility";
import clientAPI from "./clientAPI";
import IProduct from "../models/Products/IProduct";
import IOrder from "../models/Products/IOrder";
import IEntitlement from "../models/IEntitlement";
import { IUserToUpdate } from "../components/Users/UsersTab";
import { computeJsonAccountWithAddress, computeLoggedInMsp, getUpdatedAccount } from "../businessLogic/accounts";
import { getFinalProductsForAccount, computeProductOrderWithDetails } from "../businessLogic/products";
import { filterOrdersResult, nameIsBBS } from "../Utilities/productsHelper";
import IApiCreditCard from "../models/Invoices/IApiCreditCard";
import ICreditCardPayment from "../models/Invoices/ICreditCardPayment";
import { PaymentMethod } from "../models/Invoices/PaymentMethod";
import { OperationCanceledError } from "../errors/OperationCanceledError";
import IIntegrationSetup from "../models/Integrations/IIntegrationSetup";
import { IPastPlanPurgePayload } from "../models/Integrations/IPastPlanPurge";
import { IAccountFilters } from "../models/IAccountFilters";
import { IApiCredentialApplication } from "../actions/apiCredentialActions";
import IReportSchedule from "../models/ReportSchedule/Types/IReportSchedule";

const mspService = {
  async checkUser(apiUrl: string) {
    return await clientAPI.checkUser(apiUrl);
  },

  async getApiCredentialApplications(apiUrl: string, closestParentId: string) {
    return await clientAPI.getApiCredentialApplications(apiUrl, closestParentId);
  },
  async addApiCredentialApplication(apiUrl: string, closestParentId: string, newApplication: IApiCredentialApplication) {
    return await clientAPI.addApiCredentialApplication(apiUrl, closestParentId, newApplication);
  },

  async deleteApiCredentialApplication(apiUrl: string, closestParentId: string, applicationId: string) {
    return await clientAPI.deleteApiCredentialApplication(apiUrl, closestParentId, applicationId);
  },

  async renewApiCredentialApplication(apiUrl: string, closestParentId: string, applicationId: string) {
    return await clientAPI.renewApiCredentialApplication(apiUrl, closestParentId, applicationId);
  },

  async generateCustomAccessToken(apiUrl: string, bccToken: string) {
    return await clientAPI.generateCustomToken(apiUrl, bccToken);
  },

  async preFetch(apiUrl: string) {
    if (window.location.hostname !== "localhost") {
      return await clientAPI.preFetch(apiUrl);
    }
  },

  async loadPartner(apiUrl: string) {
    const result = await clientAPI.fetchLoggedInPartner(apiUrl);
    return computeLoggedInMsp(result);
  },

  async loadAccountChildren(apiUrl: string, accountId: number, cancelToken: CancelToken | undefined) {
    return await clientAPI.fetchAccountChildren(apiUrl, accountId, cancelToken);
  },

  async fetchAccountAllChildren(apiUrl: string, account: IAccount, cancelToken: CancelToken | undefined) {
    return await clientAPI.fetchAccountAllChildren(apiUrl, account.id, cancelToken);
  },

  async loadPartnerChildren(apiUrl: string) {
    return await clientAPI.fetchPartnerChildren(apiUrl);
  },

  async loadParentAccount(apiUrl: string, accountId: number, cancelToken?: CancelToken) {
    return await clientAPI.fetchParentAccount(apiUrl, accountId, cancelToken);
  },

  async fetchM365Auth(apiUrl: string, account: IAccount) {
    return await clientAPI.fetchM365Auth(apiUrl, account.closestParentId ? account.closestParentId : account.id, account.id);
  },

  async addM365Auth(apiUrl: string, account: IAccount) {
    return await clientAPI.addM365Auth(apiUrl, account.closestParentId, account.id.toString(), window.location.href);
  },

  async revokeM365Auth(apiUrl: string, account: IAccount) {
    return await clientAPI.revokeM365Auth(apiUrl, account.closestParentId, account.id);
  },

  async fetchM365DomainList(apiUrl: string, account: IAccount, skip: number, take: number) {
    return await clientAPI.fetchM365DomainList(apiUrl, account.closestParentId ? account.closestParentId : account.id, account.id, skip, take);
  },

  async fetchProduceLicenses(apiUrl: string, account: IAccount, user: string) {
    return await clientAPI.fetchProductLicenses(apiUrl, account.id, user);
  },

  async loadAccountUsers(apiUrl: string, account: IAccount, cancelToken?: CancelToken) {
    const usersResult = await clientAPI.fetchAccountUsers(apiUrl, account.id, cancelToken);
    return usersResult.users;
  },

  async loadLoggedUser(apiUrl: string) {
    return await clientAPI.fetchLoggedInUser(apiUrl);
  },

  async loadUserAccount(apiUrl: string, userId: number, accountId: number, shouldNotRefreshToken: boolean, cancelToken?: CancelToken) {
    return await clientAPI.fetchUserAccount(apiUrl, userId, accountId, shouldNotRefreshToken, cancelToken);
  },

  async addUser(apiUrl: string, accountId: number, user: IUser) {
    const newUser = {
      name: user.name,
      email: user.email,
      userBillFlag: user.billingAdministration,
      userAdminFlag: user.userManagement,
      entitlements: user.entitlements?.map((x: IEntitlement) => x.service_id),
      role: UserRoleValues.find(userRole => userRole.value === user.role)?.authDbValue,
    };
    const result = await clientAPI.addUser(apiUrl, accountId, newUser);
    return { ...user, roleDisplay: UserRoleValues.find(value => value.value === user.role)?.label, id: result.id, explicitAccountId: accountId, mfa: false, ldapLoginRequired: false };
  },

  async addNewPartnerUser(apiUrl: string, accountId: number, user: IUser) {
    const newUser = {
      name: user.name,
      email: user.email,
      userAdminFlag: user.userManagement,
      entitlements: user.entitlements?.map((x: IEntitlement) => x.service_id),
      role: UserRoleValues.find(userRole => userRole.value === user.role)?.authDbValue,
    };
    const result = await clientAPI.addNewPartnerUser(apiUrl, accountId, newUser);
    return { ...user, id: result.id, explicitAccountId: accountId };
  },

  async editUser(apiUrl: string, userId: number, updatedUser: IUserToUpdate) {
    return await clientAPI.editUser(apiUrl, userId, updatedUser);
  },

  async editUserRole(apiUrl: string, userId: number, accountId: number, role: string, entitlements: number[]) {
    return await clientAPI.editUserRole(apiUrl, userId, accountId, role, entitlements);
  },

  async deleteUser(apiUrl: string, userId: number) {
    return await clientAPI.deleteUser(apiUrl, userId);
  },

  async loadUserEntitlements(apiUrl: string, userId: number) {
    return await clientAPI.fetchUserEntitlements(apiUrl, userId);
  },

  async loadUserEntitlementsForAccount(apiUrl: string, userId: number, accountId: number, cancelToken?: CancelToken) {
    return await clientAPI.fetchUserEntitlementsForAccount(apiUrl, userId, accountId, cancelToken);
  },

  async loadAccountAddress(apiUrl: string, account: IAccount, cancelToken: CancelToken) {
    const result = await clientAPI.fetchAccountAddress(apiUrl, account.id, cancelToken);
    return result;
  },

  async loadCountries(apiUrl: string, cancelToken?: CancelToken) {
    return await clientAPI.fetchCountries(apiUrl, cancelToken);
  },

  async loadStates(apiUrl: string, countryCode: string, cancelToken?: CancelToken) {
    return await clientAPI.fetchStates(apiUrl, countryCode, cancelToken);
  },

  async loadAccount(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return await clientAPI.fetchAccount(apiUrl, accountId, cancelToken);
  },

  async addAccount(apiUrl: string, closestParentId: number, account: IAccount, notes?: string) {
    const accountWithAddress = computeJsonAccountWithAddress(account, notes);
    return await clientAPI.addAccount(apiUrl, closestParentId, accountWithAddress);
  },

  async editAccount(apiUrl: string, account: IAccount) {
    const accountWithAddress = computeJsonAccountWithAddress(account);
    const result = await clientAPI.editAccount(apiUrl, account);
    const addressResult = await clientAPI.editAddress(apiUrl, accountWithAddress);
    const updatedAccount = getUpdatedAccount(account, result, addressResult);
    return updatedAccount;
  },

  async editAccountNotes(apiUrl: string, account: IAccount, notes: string) {
    return await clientAPI.editAccountNotes(apiUrl, account, notes);
  },

  async deleteAccount(apiUrl: string, account: IAccount) {
    return await clientAPI.deleteAccount(apiUrl, account.id);
  },

  async loadSubpartnersWithOrders(apiUrl: string, cancelToken: CancelToken | undefined) {
    return await clientAPI.fetchSubpartnersWithOrders(apiUrl, cancelToken);
  },

  async loadProductsIdsAndName(apiUrl: string, accountId: number, mspAccounts: IAccount[], cancelToken: CancelToken | undefined) {
    const ordersResult = await clientAPI.fetchOrders(apiUrl, accountId, cancelToken, true);
    const activeOrders = filterOrdersResult(ordersResult);

    const orderPromises = activeOrders.map(async (product: IOrder) => {
      return await mspService.getSerialsForProduct(apiUrl, accountId, product, mspAccounts, cancelToken, true);
    });
    const finalOrders: IProduct[] = await Promise.all(orderPromises);
    const result = getFinalProduct(finalOrders);
    return result;
  },

  async getSerialsForProduct(apiUrl: string, accountId: number, product: IOrder, mspAccounts: IAccount[], cancelToken: CancelToken | undefined, getSerialsOnlyForBBS: boolean) {
    let serials = { serials: [] };
    if ((getSerialsOnlyForBBS && nameIsBBS(product.familyName)) || !getSerialsOnlyForBBS) {
      try {
        serials = await clientAPI.fetchOrderSerials(apiUrl, accountId, product.lineItemId, cancelToken);
      } catch (error: any) {
        if (!(error instanceof OperationCanceledError)) {
          console.warn(`Could not retrive serials for account id ${accountId} regarding orderline ${product.lineItemId}.`);
          if (error && error.errorMessage) {
            console.warn(`Api error message: ${error.errorMessage}`);
          }
        } else {
          throw error;
        }
      }
    }
    if (serials && serials.serials) {
      return computeProductOrderWithDetails(product, serials.serials, mspAccounts);
    } else {
      return computeProductOrderWithDetails(product, undefined, mspAccounts);
    }
  },

  async loadOrders(apiUrl: string, accountId: number, cancelToken: CancelToken | undefined, shouldNotRefreshToken: boolean) {
    return await clientAPI.fetchOrders(apiUrl, accountId, cancelToken, shouldNotRefreshToken);
  },

  async loadSerialsAndComputeProducts(apiUrl: string, orders: any, accountId: number, accountType: string, mspAccounts: IAccount[], cancelToken: CancelToken | undefined, shouldGetSerials?: boolean) {
    let resultOrders: IProduct[] = [];
    if (shouldGetSerials === undefined || shouldGetSerials) {
      const orderPromises = orders.map(async (product: IOrder) => {
        return await mspService.getSerialsForProduct(apiUrl, accountId, product, mspAccounts, cancelToken, false);
      });
      resultOrders = await Promise.all(orderPromises);
    } else {
      resultOrders = orders.map((product: IOrder) => {
        return computeProductOrderWithDetails(product, undefined, mspAccounts);
      });
    }
    const finalOrders = getFinalProductsForAccount(resultOrders, accountType);
    return finalOrders;
  },

  async loadProductSerials(apiUrl: string, accountId: number, product: IProduct, cancelToken: CancelToken | undefined) {
    return await clientAPI.fetchOrderSerials(apiUrl, accountId, product.id, cancelToken);
  },

  async activateProduct(apiUrl: string, account: IAccount, product: IProduct) {
    return await clientAPI.activateProduct(apiUrl, account.id, product.id, account.closestParentId);
  },

  async deactivateProduct(apiUrl: string, account: IAccount, product: IProduct) {
    return await clientAPI.deactivateProduct(apiUrl, account.id, product.id);
  },

  async cancelSerial(apiUrl: string, account: IAccount, product: IProduct, serialId: string) {
    return await clientAPI.cancelSerial(apiUrl, account.id, product.id, serialId);
  },

  async retryActivationProduct(apiUrl: string, account: IAccount, product: IProduct, bccProductId: number) {
    return await clientAPI.retryActivationProduct(apiUrl, account.id, product.id, bccProductId);
  },

  async assignProduct(apiUrl: string, account: IAccount, product: IProduct) {
    return await clientAPI.assignProduct(apiUrl, account.id, product.id, account.closestParentId);
  },

  async unassignProduct(apiUrl: string, account: IAccount, product: IProduct) {
    return await clientAPI.unassignProduct(apiUrl, account.id, product.id);
  },

  async loadBundleServicesSkus(apiUrl: string, bundleSku: string, cancelToken: CancelToken | undefined) {
    return await clientAPI.fetchBundleServicesSkus(apiUrl, bundleSku, cancelToken);
  },

  async changeSerial(apiUrl: string, accountId: number, orderlineItemId: string, serial: string, lineItemId: string) {
    return await clientAPI.changeSerial(apiUrl, accountId, orderlineItemId, serial, lineItemId);
  },

  async queueExportUsersRequest(apiUrl: string, accountId: number, bccAccountIds: number[], excludedBccAccountIds: number[], selectAll: boolean) {
    return await clientAPI.queueExportUsersRequest(apiUrl, accountId, bccAccountIds, excludedBccAccountIds, selectAll);
  },

  async fetchChangeSerialOptions(apiUrl: string, accountId: number, orderlineItemId: string, serial: string, mspAccounts: IAccount[], cancelToken: CancelToken | undefined) {
    const orders = await clientAPI.fetchChangeSerialOptions(apiUrl, accountId, orderlineItemId, serial, cancelToken);
    const resultOrders = orders.map((product: IOrder) => {
      return computeProductOrderWithDetails(product, undefined, mspAccounts);
    });
    return resultOrders;
  },

  async loadUsageAudit(apiUrl: string, accountId: number, filterType: string, beginWith: string, nextPageToken: string, cancelToken: CancelToken | undefined) {
    return await clientAPI.fetchUsageAudit(apiUrl, accountId, filterType, beginWith, nextPageToken, cancelToken);
  },

  async addBilledUser(apiUrl: string, accountId: number, newAuditUserEmails: string[]) {
    const newAuditUsers = { users: newAuditUserEmails };
    return await clientAPI.createBilledUser(apiUrl, accountId, newAuditUsers);
  },

  async deleteBilledUser(apiUrl: string, accountId: number, user: string) {
    return await clientAPI.deleteBilledUser(apiUrl, accountId, user);
  },

  async loadPaymentMethod(apiUrl: string, accountId: number) {
    return clientAPI.fetchPaymentMethod(apiUrl, accountId);
  },

  async editPaymentMethod(apiUrl: string, accountId: number, paymentMethod: PaymentMethod) {
    return clientAPI.editPaymentMethod(apiUrl, accountId, paymentMethod);
  },

  async loadInvoices(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return clientAPI.fetchInvoices(apiUrl, accountId, cancelToken);
  },

  async loadAccountsWithExclusions(apiUrl: string, accountId: number, invoiceDate: string, cancelToken: CancelToken) {
    return clientAPI.fetchAccountsWithExclusions(apiUrl, accountId, invoiceDate, cancelToken);
  },

  async loadAccountExcludedUsers(apiUrl: string, accountId: number, invoiceDate: string, skip: number, take: number, cancelToken: CancelToken) {
    return clientAPI.fetchAccountExclusionsUserList(apiUrl, accountId, invoiceDate, skip, take, cancelToken);
  },

  async sendEmailInvoice(apiUrl: string, accountId: number, invoiceNumber: string) {
    return clientAPI.sendEmailInvoice(apiUrl, accountId, invoiceNumber);
  },

  async addCreditCard(apiUrl: string, accountId: number, creditCard: IApiCreditCard) {
    return clientAPI.addCreditCard(apiUrl, accountId, creditCard);
  },

  async loadCreditCards(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return clientAPI.fetchCreditCards(apiUrl, accountId, cancelToken);
  },

  async setDefaultCard(apiUrl: string, accountId: number, token: string) {
    return clientAPI.setDefaultCard(apiUrl, accountId, token);
  },

  async setRecurringCard(apiUrl: string, accountId: number, token: string) {
    return clientAPI.setRecurringCard(apiUrl, accountId, token);
  },

  async deleteCreditCard(apiUrl: string, accountId: number, token: string) {
    return clientAPI.deleteCreditCard(apiUrl, accountId, token);
  },

  async payInvoice(apiUrl: string, accountId: number, invoiceNumber: string, creditCardPayment: ICreditCardPayment) {
    return clientAPI.payInvoice(apiUrl, accountId, invoiceNumber, creditCardPayment);
  },

  async fetchLastUsageSyncTime(apiUrl: string) {
    return clientAPI.fetchLastUsageSyncTime(apiUrl);
  },

  async loadIntegrations(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return clientAPI.loadIntegrations(apiUrl, accountId, cancelToken);
  },

  async loadConnectWiseIntegrationInfo(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return clientAPI.loadConnectWiseIntegrationInfo(apiUrl, accountId, cancelToken);
  },

  async loadCWIntegrationAccounts(apiUrl: string, accountId: number, skip: number, take: number, orderBy: string | undefined, ascending: boolean | undefined, unlinkedOnly: boolean, cancelToken: CancelToken) {
    return clientAPI.loadCWIntegrationAccounts(apiUrl, accountId, skip, take, orderBy, ascending, unlinkedOnly, cancelToken);
  },

  async loadCWIntegrationFilteredAccounts(apiUrl: string, accountId: number, forAccount: string | undefined, forCompany: string | undefined, unlinkedOnly: boolean, cancelToken: CancelToken) {
    return clientAPI.loadCWIntegrationFilteredAccounts(apiUrl, accountId, forAccount, forCompany, unlinkedOnly, cancelToken);
  },

  async deleteIntegration(apiUrl: string, accountId: number) {
    return clientAPI.deleteIntegration(apiUrl, accountId);
  },

  async loadCWIntegrationLogs(apiUrl: string, accountId: number, cancelToken: CancelToken, take: number) {
    return clientAPI.loadCWIntegrationLogs(apiUrl, accountId, cancelToken, take);
  },

  async addIntegration(apiUrl: string, accountId: number, integrationSetup: IIntegrationSetup) {
    return clientAPI.addIntegration(apiUrl, accountId, integrationSetup);
  },

  async editIntegration(apiUrl: string, accountId: number, integrationSetup: IIntegrationSetup) {
    return clientAPI.editIntegration(apiUrl, accountId, integrationSetup);
  },

  async loadIntegrationBillingMaps(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return clientAPI.loadIntegrationBillingMaps(apiUrl, accountId, cancelToken);
  },

  async loadIntegrationBillingMapsOptions(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return clientAPI.loadIntegrationBillingMapsOptions(apiUrl, accountId, cancelToken);
  },

  async resetPastPlans(apiUrl: string, accountId: number, pastPlanPurge: IPastPlanPurgePayload) {
    return clientAPI.resetPastPlans(apiUrl, accountId, pastPlanPurge);
  },

  async setIntegrationBillingMaps(apiUrl: string, accountId: number, billingMaps: any) {
    return clientAPI.setIntegrationBillingMaps(apiUrl, accountId, billingMaps);
  },

  async loadIntegrationAccountsCompanies(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return clientAPI.loadIntegrationAccountsCompanies(apiUrl, accountId, cancelToken);
  },

  async loadIntegrationAccountsCountCompanies(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return clientAPI.loadIntegrationAccountsCountCompanies(apiUrl, accountId, cancelToken);
  },

  async loadIntegrationAccountsSearchCompanies(apiUrl: string, accountId: number, cancelToken: CancelToken, keyword: string) {
    return clientAPI.loadIntegrationAccountsSearchCompanies(apiUrl, accountId, cancelToken, keyword);
  },

  async linkAccount(apiUrl: string, accountId: number, companyId: number) {
    return clientAPI.linkAccount(apiUrl, accountId, companyId);
  },

  async unlinkAccount(apiUrl: string, accountId: number) {
    return clientAPI.unlinkAccount(apiUrl, accountId);
  },

  async loadCurrency(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return clientAPI.fetchCurrency(apiUrl, accountId, cancelToken);
  },

  async exportCsv(apiUrl: string, payloadAccountIds: string) {
    return clientAPI.exportCsv(apiUrl, payloadAccountIds);
  },

  async addReportSchedule(apiUrl: string, accountId: number, reportSchedule: IReportSchedule) {
    return await clientAPI.addReportSchedule(apiUrl, accountId, reportSchedule);
  },

  async updateReportSchedule(apiUrl: string, accountId: number, reportSchedule: IReportSchedule) {
    return await clientAPI.updateReportSchedule(apiUrl, accountId, reportSchedule);
  },

  async deleteReportSchedule(apiUrl: string, accountId: number, id: number) {
    return await clientAPI.deleteReportSchedule(apiUrl, accountId, id);
  },

  async filterAccounts(apiUrl: string, accountId: number, filters: IAccountFilters, directOnly?: boolean, cancelToken?: CancelToken) {
    return clientAPI.filterAccounts(apiUrl, accountId, filters, directOnly, cancelToken);
  },

  async getAccountInfo(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return clientAPI.fetchAccountInfo(apiUrl, accountId, cancelToken);
  },
  async loadAccountEchoUserName(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return clientAPI.fetchAccountEchoUserName(apiUrl, accountId, cancelToken);
  },
  async getAllSerials(apiUrl: string, accountId: number, cancelToken: CancelToken) {
    return clientAPI.fetchAllSerials(apiUrl, accountId, cancelToken);
  },
  async getReportSchedules(apiUrl: string, account: IAccount) {
    return clientAPI.getReportSchedules(apiUrl, account.id);
  },
  async getReportSchedule(apiUrl: string, account: IAccount, scheduleId: number) {
    return clientAPI.getReportSchedule(apiUrl, account.id, scheduleId);
  },
};

export default mspService;
