import React from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store/store";
import { ReportScheduleType } from "../../../../models/ReportSchedule/ReportScheduleType";

interface IEmailRecipientStepProps {
  reportType: ReportScheduleType;
  isEditMode?: boolean;
}

const EmailRecipientStep: React.FC<IEmailRecipientStepProps> = ({ reportType, isEditMode = undefined }) => {
  const loggedInUserEmail = useSelector((state: IAppState) => state.generalState.loggedUser?.email);

  function getReportTypeString(reportType: ReportScheduleType) {
    switch (reportType) {
      case ReportScheduleType.UserBillingList:
        return "User Billing List";
      case ReportScheduleType.UsageData:
        return "Usage Data";
      default:
        return "";
    }
  }
  return (
    <div data-testid="emailRecipientStepMessage">
      <p style={{ textAlign: "justify", width: "auto" }}>
        Click the <b>{isEditMode ? "Update" : "Add"}</b> button to send an email with the {getReportTypeString(reportType)} CSV file at the scheduled time to <b>{loggedInUserEmail}</b>. The email may take up to one hour to complete depending on file size.
      </p>
    </div>
  );
};

export default EmailRecipientStep;
