import React, { ChangeEvent } from "react";
import { Typography, Grid } from "@cuda-networks/bds-core";

interface IDailyFrequencyProps {
  timeValue: string;
  onTimeValueChanged: (event: ChangeEvent<HTMLInputElement>) => void;
}

const DailyFrequency: React.FC<IDailyFrequencyProps> = ({ timeValue, onTimeValueChanged }) => {
  return (
    <Grid item style={{ minWidth: 150 }} data-testid="reportScheduleTimePicker">
      <Typography style={{ marginBottom: 10 }}>Time of Day</Typography>
      <input type="time" value={timeValue} onChange={onTimeValueChanged} style={{ height: "35px" }} />
    </Grid>
  );
};

export default DailyFrequency;
