import { ReportScheduleDaysOfMonthBitmask } from "../ReportScheduleDaysOfMonthBitmask";
import { ReportScheduleFrequencyType } from "../ReportScheduleFrequencyType";
import { ReportScheduleMonthBitmask } from "../ReportScheduleMonthBitmask";
import { ReportScheduleStatus } from "../ReportScheduleStatus";
import { ReportScheduleType } from "../ReportScheduleType";
import { ReportScheduleBase } from "./ReportScheduleBase";

class AnnualReportSchedule extends ReportScheduleBase {
  frequencyType: ReportScheduleFrequencyType = ReportScheduleFrequencyType.Annually;
  month: ReportScheduleMonthBitmask;
  daysOfMonth: ReportScheduleDaysOfMonthBitmask;

  constructor(name: string, type: ReportScheduleType, timeOfDayHour: number, timeOfDayMinute: number, month: ReportScheduleMonthBitmask, daysOfMonth: ReportScheduleDaysOfMonthBitmask, userTimeZone: string, includedBccAccountIds: number[] = [0], excludedBccAccountIds: number[] = [], id: number = 0, numberOfAccounts: number = 0, status?: ReportScheduleStatus, reportingEnabled: boolean = true, lastExportDateUtc?: Date | null) {
    super(name, type, timeOfDayHour, timeOfDayMinute, userTimeZone, includedBccAccountIds, excludedBccAccountIds, id, numberOfAccounts, status, reportingEnabled, lastExportDateUtc);
    this.month = month;
    this.daysOfMonth = daysOfMonth;
  }
}
export default AnnualReportSchedule;
