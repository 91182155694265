import React, { useEffect, useState } from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import { Card, CardContent, CardHeader, Button, CircularProgress, Tooltip } from "@cuda-networks/bds-core";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useDocumentTitle, { PageTitles } from "../../../Utilities/useDocumentTitle";
import { IAppState } from "../../../store/store";
import NavigateTo from "../../NavigateTo";
import { getAccountIdFromUrl } from "../../../Utilities/UrlParser";
import ExportUsageDialog from "../../Products/ExportUsageDialog";
import ExportUserBillingListDialog from "../../Products/ExportUserBillingListDialog";
import { ActionMessages, ActionTypes } from "../../../actions/ActionTypes";
import { setSnackBarMessage } from "../../../actions/generalActions";
import { addReportScheduleAction, cancelReportScheduleAction, deleteReportScheduleAction, exportCsvReportAction, getReportSchedule, getReportSchedules, runReportScheduleAction, updateReportScheduleAction } from "../../../actions/productActions";
import ActionMessageType from "../../../models/ActionMessageType";
import IAccount from "../../../models/IAccount";
import ReportSchedulesTable from "./ReportSchedulesTable";
import IReportSchedule from "../../../models/ReportSchedule/Types/IReportSchedule";
import IReportScheduleForDisplay from "../../../models/ReportSchedule/IReportScheduleForDisplay";
import { createReportScheduleForDisplay } from "../../../models/ReportScheduleFrequencyConverter";
import AddEditScheduleDialog from "./AddEditScheduleDialog";
import { IReportScheduleActionsProps } from "./ReportScheduleActions";
import { ReportScheduleStatus } from "../../../models/ReportSchedule/ReportScheduleStatus";
import DeleteScheduleDialog from "./DeleteScheduleDialog";
import { ReportScheduleBase } from "../../../models/ReportSchedule/Types/ReportScheduleBase";
import { ReportScheduleType } from "../../../models/ReportSchedule/ReportScheduleType";
import { ReportScheduleFrequencyType } from "../../../models/ReportSchedule/ReportScheduleFrequencyType";
import AnnualReportSchedule from "../../../models/ReportSchedule/Types/AnnualReportSchedule";
import { ReportScheduleDaysOfMonthBitmask } from "../../../models/ReportSchedule/ReportScheduleDaysOfMonthBitmask";
import DailyReportSchedule from "../../../models/ReportSchedule/Types/DailyReportSchedule";
import MonthlyReportSchedule from "../../../models/ReportSchedule/Types/MonthlyReportSchedule";
import WeeklyReportSchedule from "../../../models/ReportSchedule/Types/WeeklyReportSchedule";
import { ReportScheduleDaysOfWeekBitmask } from "../../../models/ReportSchedule/ReportScheduleDaysOfWeekBitmask";

const ReportSchedules = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accountId = getAccountIdFromUrl();
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const loggedUser = useSelector((state: IAppState) => state.generalState.loggedUser);
  const [showGenerateCsvReport, setShowGenerateCsvReport] = useState(false);
  const [showExportUserBillingList, setShowExportUserBillingList] = useState(false);
  const [showAddSchedule, setShowAddSchedule] = useState(false);
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [reportSchedulesForDisplay, setReportSchedulesForDisplay] = useState<IReportScheduleForDisplay[]>([]);
  const [showLoading, setShowLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [showDeleteScheduleConfirmation, setShowDeleteScheduleConfirmation] = useState(false);
  const [selectedScheduleId, setSelectedScheduleId] = useState<number>(0);
  const [selectedReportSchedule, setSelectedReportSchedule] = useState<IReportSchedule>();
  const [selectedScheduleName, setSelectedScheduleName] = useState("");
  const accountNames = useSelector((state: IAppState) => state.accountState.accountsNames);
  const [isAddScheduleDisabled, setIsAddScheduleDisabled] = useState<boolean>(false);
  const maxReportSchedules = 100;

  useDocumentTitle(selectedAccount, PageTitles.ReportSchedules);

  const navigateTo = `/accounts/${accountId}/products-services`;
  const handleBack = (event: React.SyntheticEvent) => {
    navigate(`/accounts/${accountId}/products-services`);
  };

  const handleCancelExportUsage = () => {
    setShowGenerateCsvReport(false);
  };

  const exportCsv = (mspAccountLoggedIn: IAccount, selectedAccount: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(exportCsvReportAction(mspAccountLoggedIn, selectedAccount));
      resolve(result);
    });

  const handleOnExportUsage = () => {
    setActionInProgress(true);
    if (selectedAccount) {
      exportCsv(mspAccountLoggedIn, selectedAccount).then(result => {
        if (result) {
          setShowGenerateCsvReport(false);
          dispatch(
            setSnackBarMessage({
              message: ActionMessages[ActionTypes.ExportCsvReport].infoMessage,
              type: ActionMessageType.Info,
            }),
          );
        }
        setActionInProgress(false);
      });
    }
  };

  const onExportUserBillingListSubmit = () => {
    setShowExportUserBillingList(false);
  };

  const onExportUserBillingListCancel = () => {
    setShowExportUserBillingList(false);
  };

  const addEditReportSchedule = (selectedAccount: IAccount, reportSchedule: IReportSchedule) =>
    new Promise<any>((resolve, reject) => {
      const action = reportSchedule.id ? updateReportScheduleAction(selectedAccount, reportSchedule) : addReportScheduleAction(selectedAccount, reportSchedule);
      const result = dispatch(action);
      resolve(result);
    });

  const updateReportSchedule = (selectedAccount: IAccount, reportSchedule: IReportSchedule) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(updateReportScheduleAction(selectedAccount, reportSchedule));
      resolve(result);
    });

  const deleteReportSchedule = (selectedAccount: IAccount, id: number) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(deleteReportScheduleAction(selectedAccount, id));
      resolve(result);
    });

  const runReportSchedule = (selectedAccount: IAccount, reportSchedule: IReportSchedule) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(runReportScheduleAction(selectedAccount, reportSchedule));
      resolve(result);
    });

  const cancelReportSchedule = (selectedAccount: IAccount, reportSchedule: IReportSchedule) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(cancelReportScheduleAction(selectedAccount, reportSchedule));
      resolve(result);
    });

  const onAddEditReportScheduleSucceeded = (reportSchedule: IReportSchedule, isEditting: boolean) => {
    dispatch(
      setSnackBarMessage({
        message: ActionMessages[isEditting ? ActionTypes.UpdateReportSchedule : ActionTypes.AddReportSchedule].infoMessage,
        type: ActionMessageType.Info,
      }),
    );
    const previousStatus = reportSchedulesForDisplay.find(schedule => schedule.id === reportSchedule.id)?.status;
    const scheduleForDisplay = createReportScheduleForDisplay(reportSchedule, true, accountNames);
    if (isEditting) {
      // do not update the status during Edit
      scheduleForDisplay.status = previousStatus ?? ReportScheduleStatus.None;
      const updatedSchedules = reportSchedulesForDisplay.map(schedule => {
        if (schedule.id === reportSchedule.id) {
          return {
            ...schedule,
            ...scheduleForDisplay,
          };
        }
        return schedule;
      });
      setReportSchedulesForDisplay(updatedSchedules);
    } else {
      setReportSchedulesForDisplay(prevSchedules => [...prevSchedules, scheduleForDisplay]);
    }
  };

  const submitReportSchedule = (reportSchedule: IReportSchedule) => {
    const isEditting = reportSchedule.id !== undefined && reportSchedule.id > 0;
    const isAdding = !isEditting;
    if (selectedAccount) {
      setActionInProgress(true);
      addEditReportSchedule(selectedAccount, reportSchedule).then(result => {
        setActionInProgress(false);
        if (result) {
          if (isAdding) {
            const scheduleId = parseInt(result as string);
            if (isNaN(scheduleId)) {
              throw new Error("Invalid schedule ID returned from the server");
            }
            reportSchedule.id = scheduleId;
          }
          onAddEditReportScheduleSucceeded(reportSchedule, isEditting);
        }
      });
    }
  };

  const onAddScheduleCancel = () => {
    setShowAddSchedule(false);
  };

  const onAddReportSchedule = () => {
    setSelectedScheduleId(0);
    setSelectedReportSchedule(undefined);
    setShowAddSchedule(true);
  };

  const onEditReportSchedule = (id: number) => {
    setSelectedScheduleId(id);
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getReportSchedule(selectedAccount, id));
      resolve(result);
    })
      .then((schedule: any) => {
        setSelectedReportSchedule(schedule);
        setShowAddSchedule(true);
      })
      .catch(() => {
        dispatch(
          setSnackBarMessage({
            message: `Failed to load report schedule ${id}!`,
            type: ActionMessageType.Error,
          }),
        );
      });
  };

  const onAddEditScheduleSubmit = (reportSchedule: IReportSchedule) => {
    setShowAddSchedule(false);
    submitReportSchedule(reportSchedule);
  };

  const updateScheduleOnGui = (id: number, status: ReportScheduleStatus) => {
    const updatedSchedules = reportSchedulesForDisplay.map(schedule => {
      if (schedule.id === id) {
        return { ...schedule, status: status };
      }
      return schedule;
    });
    setReportSchedulesForDisplay(updatedSchedules);
  };

  const updateToggledStatusOnGui = (id: number, reportingEnabled: boolean) => {
    const updatedSchedules = reportSchedulesForDisplay.map(schedule => {
      if (schedule.id === id) {
        return { ...schedule, reportingEnabled: reportingEnabled };
      }
      return schedule;
    });
    setReportSchedulesForDisplay(updatedSchedules);
  };

  const handleRunReport = (id: number) => {
    if (selectedAccount) {
      let status = ReportScheduleStatus.Pending;
      updateScheduleOnGui(id, status);

      const reportSchedule = {
        id: id,
        status: status,
      };
      runReportSchedule(selectedAccount, reportSchedule).then(result => {
        if (result) {
          dispatch(
            setSnackBarMessage({
              message: ActionMessages[ActionTypes.RunReportSchedule].infoMessage,
              type: ActionMessageType.Info,
            }),
          );
        } else {
          status = ReportScheduleStatus.Failed;
          // update the UI
          updateScheduleOnGui(id, status);
        }
        setActionInProgress(false);
      });
    }
  };

  const handleToggleReportSchedule = (id: number): void => {
    if (selectedAccount) {
      const reportSchedule = reportSchedulesForDisplay.find(schedule => schedule.id === id);
      if (!reportSchedule) {
        return;
      }
      const reportingEnabled = reportSchedule.reportingEnabled === true;
      // toggle it, if it's true it becomes false and viceversa
      const reportingEnabledForUpdate = !reportingEnabled;
      updateToggledStatusOnGui(id, reportingEnabledForUpdate);
      const reportScheduleForUpdate = {
        id: reportSchedule.id,
        reportingEnabled: reportingEnabledForUpdate,
      };
      updateReportSchedule(selectedAccount, reportScheduleForUpdate).then(result => {
        if (result) {
          dispatch(
            setSnackBarMessage({
              message: ActionMessages[ActionTypes.UpdateReportSchedule].infoMessage,
              type: ActionMessageType.Info,
            }),
          );
        } else {
          // failed, set it back to what it was before
          updateToggledStatusOnGui(id, reportingEnabled);
        }
        setActionInProgress(false);
      });
    }
  };

  const performDelete = (id: number) => {
    if (!selectedAccount) {
      return;
    }
    const scheduleToDelete = reportSchedulesForDisplay.find(schedule => schedule.id === id);
    if (!scheduleToDelete) {
      return;
    }

    deleteReportSchedule(selectedAccount, scheduleToDelete.id).then(result => {
      if (result) {
        dispatch(
          setSnackBarMessage({
            message: ActionMessages[ActionTypes.DeleteReportSchedule].infoMessage,
            type: ActionMessageType.Info,
          }),
        );
        // delete it from the UI
        const updatedSchedules = reportSchedulesForDisplay.filter(schedule => schedule.id !== id);
        setReportSchedulesForDisplay(updatedSchedules);
      }
      setActionInProgress(false);
    });
  };

  const handleDeleteSchedule = (id: number): void => {
    if (selectedAccount) {
      setSelectedScheduleId(id);
      setSelectedScheduleName(reportSchedulesForDisplay.find(schedule => schedule.id === id)?.name ?? "");
      setShowDeleteScheduleConfirmation(true);
    }
  };

  const handleCancelReport = (id: number): void => {
    if (!selectedAccount) {
      return;
    }
    const reportSchedule = reportSchedulesForDisplay.find(schedule => schedule.id === id);
    if (!reportSchedule) {
      return;
    }
    const originalStatus = reportSchedule.status ?? ReportScheduleStatus.None;
    updateScheduleOnGui(id, ReportScheduleStatus.Pending);

    const reportScheduleForCancel = {
      id: reportSchedule.id,
      status: ReportScheduleStatus.Cancelled,
    };
    cancelReportSchedule(selectedAccount, reportScheduleForCancel).then(result => {
      if (result) {
        updateScheduleOnGui(id, ReportScheduleStatus.Cancelled);
        dispatch(
          setSnackBarMessage({
            message: ActionMessages[ActionTypes.CancelReportSchedule].infoMessage,
            type: ActionMessageType.Info,
          }),
        );
      } else {
        // failed, set it back to what it was before
        updateScheduleOnGui(id, originalStatus);
      }
      setActionInProgress(false);
    });
  };

  const actions: IReportScheduleActionsProps = {
    reportingEnabled: true,
    onRunReport: handleRunReport,
    onToggleReportSchedule: handleToggleReportSchedule,
    onDeleteSchedule: handleDeleteSchedule,
    onCancelReport: handleCancelReport,
    status: ReportScheduleStatus.None,
  };

  const areSchedulesEqual = (schedule1: IReportScheduleForDisplay, schedule2: IReportScheduleForDisplay): boolean => {
    return schedule1.id === schedule2.id && schedule1.name === schedule2.name && schedule1.type === schedule2.type && schedule1.frequencyType === schedule2.frequencyType && schedule1.numberOfAccounts === schedule2.numberOfAccounts && schedule1.status === schedule2.status && schedule1.lastExport === schedule2.lastExport && schedule1.reportingEnabled === schedule2.reportingEnabled;
  };

  const populateReportSchedules = (selectedAccount: IAccount): void => {
    if (initialLoad) {
      setShowLoading(true);
    }
    new Promise<any>((resolve, reject) => {
      const result = dispatch(getReportSchedules(selectedAccount));
      resolve(result);
    })
      .then((newReportSchedules: any) => {
        if (initialLoad) {
          setShowLoading(false);
          setInitialLoad(false);
        }
        let newSchedulesForDisplay: IReportScheduleForDisplay[] = newReportSchedules.map((schedule: IReportSchedule) => {
          return createReportScheduleForDisplay(schedule, false, accountNames);
        });
        if (newSchedulesForDisplay.length === reportSchedulesForDisplay.length && newSchedulesForDisplay.every((schedule, index) => areSchedulesEqual(schedule, reportSchedulesForDisplay[index]))) {
          // if the schedules are the same, don't update the UI
          return;
        }
        setReportSchedulesForDisplay(newSchedulesForDisplay);
      })
      .catch(() => {
        dispatch(
          setSnackBarMessage({
            message: "Failed to load report schedules!",
            type: ActionMessageType.Error,
          }),
        );
      });
  };

  const onConfirmCancelDeleteSchedule = () => {
    setShowDeleteScheduleConfirmation(false);
  };

  const onConfirmDeleteSchedule = () => {
    setShowDeleteScheduleConfirmation(false);
    performDelete(selectedScheduleId);
    setSelectedScheduleId(0);
  };

  const getSelectedReportSchedule = (selectedReportSchedule: IReportSchedule | undefined): ReportScheduleBase | undefined => {
    if (!selectedReportSchedule) {
      return undefined;
    }
    const includedAccounts = selectedReportSchedule.includedBccAccountIds ?? [];
    const excludedAccounts = selectedReportSchedule.excludedBccAccountIds ?? [];
    if (selectedReportSchedule.frequencyType === ReportScheduleFrequencyType.Daily) {
      return new DailyReportSchedule(selectedReportSchedule.name ?? "", selectedReportSchedule.type ?? ReportScheduleType.UsageData, selectedReportSchedule.timeOfDayHour ?? 0, selectedReportSchedule.timeOfDayMinute ?? 0, selectedReportSchedule.userTimeZone ?? "America/New_York", includedAccounts, excludedAccounts, selectedReportSchedule.id, selectedReportSchedule.numberOfAccounts ?? 0, selectedReportSchedule.status ?? ReportScheduleStatus.None, selectedReportSchedule.reportingEnabled ?? true, selectedReportSchedule.lastExportDateUtc);
    } else if (selectedReportSchedule.frequencyType === ReportScheduleFrequencyType.Weekly) {
      return new WeeklyReportSchedule(selectedReportSchedule.name ?? "", selectedReportSchedule.type ?? ReportScheduleType.UsageData, selectedReportSchedule.timeOfDayHour ?? 0, selectedReportSchedule.timeOfDayMinute ?? 0, selectedReportSchedule.daysOfWeek ?? ReportScheduleDaysOfWeekBitmask.None, selectedReportSchedule.userTimeZone ?? "America/New_York", includedAccounts, excludedAccounts, selectedReportSchedule.id, selectedReportSchedule.numberOfAccounts ?? 0, selectedReportSchedule.status ?? ReportScheduleStatus.None, selectedReportSchedule.reportingEnabled ?? true, selectedReportSchedule.lastExportDateUtc);
    } else if (selectedReportSchedule.frequencyType === ReportScheduleFrequencyType.Monthly) {
      return new MonthlyReportSchedule(selectedReportSchedule.name ?? "", selectedReportSchedule.type ?? ReportScheduleType.UsageData, selectedReportSchedule.timeOfDayHour ?? 0, selectedReportSchedule.timeOfDayMinute ?? 0, selectedReportSchedule.daysOfMonth ?? ReportScheduleDaysOfMonthBitmask.None, selectedReportSchedule.userTimeZone ?? "America/New_York", includedAccounts, excludedAccounts, selectedReportSchedule.id, selectedReportSchedule.numberOfAccounts ?? 0, selectedReportSchedule.status ?? ReportScheduleStatus.None, selectedReportSchedule.reportingEnabled ?? true, selectedReportSchedule.lastExportDateUtc);
    } else if (selectedReportSchedule.frequencyType === ReportScheduleFrequencyType.Annually) {
      return new AnnualReportSchedule(selectedReportSchedule.name ?? "", selectedReportSchedule.type ?? ReportScheduleType.UsageData, selectedReportSchedule.timeOfDayHour ?? 0, selectedReportSchedule.timeOfDayMinute ?? 0, selectedReportSchedule.month ?? ReportScheduleDaysOfMonthBitmask.None, selectedReportSchedule.daysOfMonth ?? ReportScheduleDaysOfMonthBitmask.None, selectedReportSchedule.userTimeZone ?? "America/New_York", includedAccounts, excludedAccounts, selectedReportSchedule.id, selectedReportSchedule.numberOfAccounts ?? 0, selectedReportSchedule.status ?? ReportScheduleStatus.None, selectedReportSchedule.reportingEnabled ?? true, selectedReportSchedule.lastExportDateUtc);
    } else {
      throw new Error("Frequency type not supported");
    }
  };

  useEffect(() => {
    const handlePopState = (event: any) => {
      setShowGenerateCsvReport(false);
    };
    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, []);

  useEffect(() => {
    dispatch(getReportSchedules(selectedAccount));
  }, [dispatch, selectedAccount]);

  useEffect(() => {
    if (selectedAccount) {
      const fetchData = async () => {
        populateReportSchedules(selectedAccount);
      };
      fetchData(); // initial fetch
      const intervalId = setInterval(fetchData, 30 * 1000); // every 30 seconds
      return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount]);

  useEffect(() => {
    if (reportSchedulesForDisplay) {
      setIsAddScheduleDisabled(reportSchedulesForDisplay.length >= maxReportSchedules);
    }
  }, [reportSchedulesForDisplay]);

  return (
    <div>
      <Grid container item>
        <Grid container style={{ paddingBottom: "10px" }} spacing={1}>
          <NavigateTo to={navigateTo} text="Products & Services" callback={handleBack} />
        </Grid>
        <Grid item container xs={12}>
          <Card style={{ width: "100%" }}>
            <CardHeader data-testid="partnerReportSchedulesPanelTitle" title="Export CSV" style={{ textAlign: "left" }} />
            <CardContent>
              <Grid container style={{ paddingBottom: "10px", marginLeft: "20px" }} spacing={1}>
                <Grid item>
                  <Button data-testid="exportUserBillingListButton" variant={"contained"} size={"large"} style={{ marginRight: "15px", backgroundColor: "white", color: "#0088CE", borderStyle: "solid", borderColor: "#0088CE", borderWidth: "1px" }} onClick={setShowExportUserBillingList}>
                    EXPORT USER BILLING LIST
                  </Button>
                </Grid>
                <Grid item>
                  <Button data-testid={"exportUsageDataButton"} variant={"contained"} size={"large"} style={{ marginRight: "15px", backgroundColor: "white", color: "#0088CE", borderStyle: "solid", borderColor: "#0088CE", borderWidth: "1px" }} onClick={setShowGenerateCsvReport}>
                    EXPORT USAGE DATA
                  </Button>
                </Grid>

                <Grid item>
                  <Tooltip title={isAddScheduleDisabled ? `No more than ${maxReportSchedules} schedules are allowed` : ""} disableHoverListener={!isAddScheduleDisabled}>
                    <Button onClick={onAddReportSchedule} data-testid={"addScheduleButton"} disabled={isAddScheduleDisabled} color={"primary"} variant={"contained"} size={"large"} style={{ marginRight: "15px" }}>
                      Add Schedule
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <div data-testid="scheduleListItemsTable" style={{ width: "100%", padding: "20px" }}>
                  {showLoading && initialLoad ? <CircularProgress data-testid={"loadingSchedulesList"} size="24px" /> : reportSchedulesForDisplay && <ReportSchedulesTable onEdit={onEditReportSchedule} actions={actions} reportSchedules={reportSchedulesForDisplay} />}
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {showGenerateCsvReport && selectedAccount && <ExportUsageDialog showExportUsageDialogDialog={showGenerateCsvReport} onCancel={handleCancelExportUsage} isActionInProgress={actionInProgress} onExportUsageData={handleOnExportUsage} selectedAccount={selectedAccount} emailAddress={loggedUser.email} />}
      {showExportUserBillingList && <ExportUserBillingListDialog showDialog={showExportUserBillingList} isActionInProgress={actionInProgress} dialogStep={0} title="Export User Billing List" onSubmit={onExportUserBillingListSubmit} onCancel={onExportUserBillingListCancel} selectedAccount={selectedAccount} />}
      {showAddSchedule && <AddEditScheduleDialog selectedAccount={selectedAccount} reportSchedule={getSelectedReportSchedule(selectedReportSchedule)} showDialog={showAddSchedule} isActionInProgress={actionInProgress} dialogStep={0} title={!selectedScheduleId || selectedScheduleId <= 0 ? "Add Schedule" : "Edit Schedule"} onSubmit={onAddEditScheduleSubmit} onCancel={onAddScheduleCancel} />}
      {showDeleteScheduleConfirmation && selectedAccount && <DeleteScheduleDialog scheduleName={selectedScheduleName} showDialog={showDeleteScheduleConfirmation} onCancel={onConfirmCancelDeleteSchedule} onDeleteSchedule={onConfirmDeleteSchedule} isActionInProgress={false} />}
    </div>
  );
};

export default ReportSchedules;
