import React from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import { Box, CheckboxLabel } from "@cuda-networks/bds-core";
import { CheckedState } from "../../models/CheckedState";
import AccountsTableBase from "./AccountsTableBase";
import CheckBoxWithIndeterminateState from "../CheckBoxWithIndeterminateState";
import IAccount from "../../models/IAccount";

interface AccountsSelectionProps {
  displayedAccounts: IAccount[];
  allChecked: CheckedState;
  selectedCount: number;
  selectedPage: number;
  pageSize: number;
  height: string;
  width: string;
  onAllChecked: (s: boolean) => void;
  onItemSelected: (account: any) => void;
  onItemChecked: (account: any, checked: boolean) => void;
  onPageSizeChanged: (size: number) => void;
  onPageNumberChange: (page: number) => void;
}

const AccountsList: React.FC<AccountsSelectionProps> = ({ displayedAccounts, allChecked, selectedCount, selectedPage, onAllChecked, onItemSelected, onItemChecked, onPageSizeChanged, onPageNumberChange, pageSize, height, width }) => {
  return (
    <div className="noPageSize" style={{ width: width, marginLeft: 5, height: height }}>
      <div data-testid="exportUserBillingListInstructionsMessage" style={{ marginTop: -10, fontSize: 14 }}>
        Select the Accounts of which the desired User Billing Lists are to be exported.
      </div>
      {displayedAccounts.length > 0 && (
        <Box sx={{ border: 1, borderRight: 0, borderLeft: 0, borderTop: 0, borderStyle: "solid", borderColor: "rgba(0, 0, 0, 0.12)", padding: 5 }}>
          <Grid container>
            <Grid item xs={1}>
              <CheckboxLabel data-testid={"ExportUserBillingListSelectAll"} control={<CheckBoxWithIndeterminateState size="small" disabled={false} onCheck={onAllChecked} checkedState={allChecked} />} />
            </Grid>
            <Grid item xs={11} style={{ display: "inline-flex", textAlign: "center", marginLeft: -10, marginTop: 10 }}>
              <div style={{ fontWeight: "bold", marginRight: 5 }}>Select all Accounts</div>({selectedCount} Selected)
            </Grid>
          </Grid>
        </Box>
      )}
      <div data-testid="exportUserBillingListAccountsTable">
        <AccountsTableBase isTextOnlyTable={true} showExpandIcon={false} pageSize={pageSize} pageNumber={selectedPage} items={displayedAccounts} selectedAccount={undefined} hasSubpartners={false} viewSearchResults={false} loadingAccountId={undefined} onItemSelected={onItemSelected} onItemChecked={onItemChecked} onPageSizeChange={onPageSizeChanged} onPageNumberChange={onPageNumberChange} showCheckboxes={true} checkboxesDisabled={false} isBaLoggedIn={false} showInfoIcon={false} defaultPagination={[10]} />
      </div>
    </div>
  );
};

export default AccountsList;
