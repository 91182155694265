import { ReportScheduleDaysOfMonthBitmask } from "./ReportSchedule/ReportScheduleDaysOfMonthBitmask";
import IReportSchedule from "./ReportSchedule/Types/IReportSchedule";
import { ReportScheduleDaysOfWeekBitmask } from "./ReportSchedule/ReportScheduleDaysOfWeekBitmask";
import { ReportScheduleFrequencyType } from "./ReportSchedule/ReportScheduleFrequencyType";
import { ReportScheduleMonthBitmask } from "./ReportSchedule/ReportScheduleMonthBitmask";
import { ReportScheduleType } from "./ReportSchedule/ReportScheduleType";
import IReportScheduleForDisplay from "./ReportSchedule/IReportScheduleForDisplay";
import { ReportTypes } from "../components/Accounts/Reports/AddEditScheduleDialog";
import { ReportScheduleStatus } from "./ReportSchedule/ReportScheduleStatus";
import IAccount from "./IAccount";

export const createReportScheduleForDisplay = (reportSchedule: IReportSchedule, justCreated: boolean, accountNames: IAccount[]) => {
  const newScheduleForDisplay: IReportScheduleForDisplay = {
    id: reportSchedule.id ?? 0,
    name: reportSchedule.name,
    type: ReportTypes[reportSchedule.type as ReportScheduleType].value,
    frequencyType: getFrequencyForDisplay(reportSchedule),
    numberOfAccounts: getNumberOfAccountsForDisplay(reportSchedule, justCreated, accountNames),
    reportingEnabled: reportSchedule.reportingEnabled ?? false,
    lastExport: getLastExportDateForDisplay(reportSchedule),
    status: reportSchedule.status ?? ReportScheduleStatus.None,
  };
  return newScheduleForDisplay;
};

export const getNumberOfAccountsForDisplay = (schedule: IReportSchedule, justCreated: boolean, accountNames: IAccount[]): string => {
  if (schedule.type === ReportScheduleType.UsageData) {
    return "N/A"; // Usage data reports do not have a number of accounts
  }
  if (justCreated) {
    // When the record comes from the GUI (just created), the number of accounts is determined by the number of inclusions
    if (schedule.includedBccAccountIds && schedule.includedBccAccountIds.length > 0) {
      // if first element is 0, it means all accounts are included
      if (schedule.includedBccAccountIds[0] === 0) {
        return (accountNames.length - (schedule.excludedBccAccountIds?.length ?? 0)).toString();
      }
      return schedule.includedBccAccountIds.length.toString();
    }
  }
  return schedule.numberOfAccounts ? schedule.numberOfAccounts.toString() : "0";
};

/**
 * Converts the schedule's last export date UTC to user's timezone and returns it in a human-readable format.
 * @param schedule
 * @returns
 */
export const getLastExportDateForDisplay = (schedule: IReportSchedule) => {
  if (schedule.lastExportDateUtc) {
    // if the last export does not end with UTC, append it
    const lastExportDateUtc = schedule.lastExportDateUtc.toString().endsWith("Z") ? schedule.lastExportDateUtc : `${schedule.lastExportDateUtc}Z`;
    // convert from the DB UTC format to the schedule's user timezone
    const date = new Date(lastExportDateUtc).toLocaleDateString("en-US", {
      timeZone: schedule.userTimeZone,
      timeZoneName: "short",
      minute: "numeric",
      hour: "numeric",
      day: "numeric",
      year: "numeric",
      month: "numeric",
    });
    return date.toString();
  }
  return "N/A";
};

/**
 * Converts a report schedule frequency from the transport objects (API/DB) fron bitmask and enums to human-readable strings.
 * @param schedule
 * @returns
 */
export const getFrequencyForDisplay = (schedule: IReportSchedule) => {
  if (schedule.timeOfDayHour == null || schedule.timeOfDayMinute == null || !schedule.userTimeZone) {
    return "N/A";
  }
  const time = `${schedule.timeOfDayHour % 12 || 12}:${schedule.timeOfDayMinute.toString().padStart(2, "0")} ${schedule.timeOfDayHour >= 12 ? "PM" : "AM"}`;
  const timeZone = getTimeZoneAbbreviation(schedule.userTimeZone);

  if (schedule.frequencyType === ReportScheduleFrequencyType.Daily) {
    return `Daily, @${time} ${timeZone}`;
  } else if (schedule.frequencyType === ReportScheduleFrequencyType.Weekly) {
    if (schedule.daysOfWeek) {
      const days = getDaysFromBitmask(schedule.daysOfWeek);
      return `Weekly, ${days.join(", ")} @ ${time} ${timeZone}`;
    } else {
      return "Unknown";
    }
  } else if (schedule.frequencyType === ReportScheduleFrequencyType.Monthly) {
    let days = "";
    if (schedule.daysOfMonth) {
      days = getDaysOfMonthFromBitmask(schedule.daysOfMonth).join(", ");
    }
    return `Monthly, on the ${days} @ ${time} ${timeZone}`;
  } else if (schedule.frequencyType === ReportScheduleFrequencyType.Annually) {
    let months = "";
    if (schedule.month) {
      months = getMonthsFromBitmask(schedule.month)
        .map(month => month)
        .join(", ");
    }
    let days = "";
    if (schedule.daysOfMonth) {
      days = getDaysOfMonthFromBitmask(schedule.daysOfMonth).join(", ");
    }
    return `Yearly, in ${months} on the ${days} @ ${time} ${timeZone}`;
  } else {
    return "Unknown";
  }
};

const getTimeZoneAbbreviation = (ianaTimeZone: string): string => {
  const date = new Date();
  const options: Intl.DateTimeFormatOptions = {
    timeZone: ianaTimeZone,
    timeZoneName: "short",
  };
  const formatter = new Intl.DateTimeFormat("en-US", options);
  const parts = formatter.formatToParts(date);
  const timeZoneName = parts.find(part => part.type === "timeZoneName");
  return timeZoneName ? timeZoneName.value : ianaTimeZone;
};

const getDaysFromBitmask = (bitmask: number): string[] => {
  const days = [];
  const dayNames = Object.keys(ReportScheduleDaysOfWeekBitmask).filter(key => key !== "None" && isNaN(Number(key)));

  for (const day of dayNames) {
    const dayBitmask = ReportScheduleDaysOfWeekBitmask[day as keyof typeof ReportScheduleDaysOfWeekBitmask];
    if (bitmask & dayBitmask) {
      days.push(day);
    }
  }
  return days;
};

const getDaysOfMonthFromBitmask = (bitmask: number): string[] => {
  const days = [];
  const dayNames = Object.keys(ReportScheduleDaysOfMonthBitmask).filter(key => key !== "None" && isNaN(Number(key)));

  for (const day of dayNames) {
    const dayBitmask = ReportScheduleDaysOfMonthBitmask[day as keyof typeof ReportScheduleDaysOfMonthBitmask];
    if (bitmask & dayBitmask) {
      if (dayBitmask === ReportScheduleDaysOfMonthBitmask.LastDayOfMonth) {
        days.push("last day of the month");
      } else {
        const dayNumber = Math.log2(dayBitmask) + 1;
        days.push(`${dayNumber}${getOrdinalSuffix(dayNumber)}`);
      }
    }
  }
  return days;
};

const getMonthsFromBitmask = (bitmask: number): string[] => {
  const months = [];
  const monthNames = Object.keys(ReportScheduleMonthBitmask).filter(key => key !== "None" && isNaN(Number(key)));

  for (const month of monthNames) {
    const monthBitmask = ReportScheduleMonthBitmask[month as keyof typeof ReportScheduleMonthBitmask];
    if (bitmask & monthBitmask) {
      months.push(month);
    }
  }
  return months;
};

const getOrdinalSuffix = (day: number) => {
  if (day > 3 && day < 21) return "th"; // special case for 11th-13th
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
